import * as SurveyService from '../../services/survey.service'
import emptySurvey from '../../utils/emptySurvey'
import * as mappings from '../../utils/mappings'
import { ContextAction } from '../app-context'

export enum SurveyStateEnum {
  notstarted = 'not-started',
  inprogress = 'in-progress',
  completed = 'completed',
}

/**
 * Fetch forms and surveys
 */
export const getSurveysAction =
  (patientId?: string): ContextAction =>
  async (produceState) => {
    await produceState((draft) => {
      draft.arrayOfFormPostOfToday.state = 'loading'
    })
    try {
      const arrayOfApiFormPostOfToday = await SurveyService.getFormPostOfToday(
        patientId,
      )
      const arrayOfFormPostOfToday = mappings.fromApiFormPostOfToday(
        arrayOfApiFormPostOfToday,
      )
      const allFormsWithSurveyApi: Array<ApiFormWithSurvey> =
        await SurveyService.getForms(patientId)
      const allNakedForms: Array<FormUntouched> = mappings.fromApiForms(
        allFormsWithSurveyApi,
      )
      const allSurveys = allNakedForms.map((form) => form.surveys).flat()
      const allForms = mappings.fromArrayFormUntouchedToArrayForm(
        allNakedForms,
        arrayOfFormPostOfToday,
      )

      await produceState((draft) => {
        draft.allSurveys = allSurveys
        draft.arrayOfFormPostOfToday.state = 'loaded'
        draft.allForms.data = allForms
        draft.arrayOfFormPostOfToday.data = arrayOfFormPostOfToday
      })
    } catch (error) {
      console.error(error)
      await produceState((draft) => {
        draft.currentSurvey = emptySurvey
        draft.allSurveys = []
        draft.arrayOfFormPostOfToday.state = 'error'
        draft.currentFormPostId = undefined
      })
    }
  }

/**
 * Store user answers in context
 */
export const updateUserAnswersAction =
  (questionWithUserAnswers: Question): ContextAction =>
  async (produceState) => {
    await produceState((draft) => {
      if (draft.currentSurvey && draft.currentSurvey.questions) {
        draft.currentSurvey.questions = draft.currentSurvey.questions.map(
          (qst) => {
            if (qst.id === questionWithUserAnswers.id) {
              qst.userAnswers = questionWithUserAnswers.userAnswers
            }
            return qst
          },
        )
      }
    })
  }

/**
 * Store last seen question in context
 */
export const setLastSeenQuestionAction =
  (lastSeenQuestion: any): ContextAction =>
  async (produceState) => {
    await produceState((draft) => {
      draft.lastSeenQuestion = lastSeenQuestion
    })
  }

export const createFormPostAction =
  (formId: string): ContextAction =>
  async (produceState, getState) => {
    const { user, patient } = getState()

    try {
      await produceState((draft) => {
        draft.createFormPostStatus = 'loading'
      })

      let apiFormPost: ApiFormPost
      if (user.data?.role === 'inspector') {
        apiFormPost = await SurveyService.createFormPost(
          formId,
          patient.data?.id,
          user.data?.id,
        )
      } else {
        apiFormPost = await SurveyService.createFormPost(formId)
      }

      const formPost: FormPost = mappings.fromApiFormPost(apiFormPost)

      await produceState(async (draft) => {
        draft.createFormPostStatus = 'created'
        draft.currentFormPostId = formPost.id
        draft.currentFormPost = formPost
      })
    } catch (error) {
      console.error(error)
      await produceState((draft) => {
        draft.createFormPostStatus = 'error'
        draft.currentFormPostId = undefined
      })
    }
  }

/**
 * Send user answers to API
 */
export const sendUserSurveyAction =
  (userSurveys: Survey[]): ContextAction =>
  async (produceState, getState) => {
    const { dispatch, currentFormPostId, patient } = getState()

    try {
      await produceState((draft) => {
        draft.sendUserSurveyStatus = 'loading'
      })

      const answers = userSurveys
        .map((userSurvey) => mappings.userSurveyToAnswers(userSurvey))
        .flat()

      if (currentFormPostId) {
        await SurveyService.sendSurvey({ answers }, currentFormPostId)
      }

      await produceState(async (draft) => {
        draft.sendUserSurveyStatus = 'updated'
      })
      await dispatch(updateSurveyStateAction(SurveyStateEnum.completed))
      await dispatch(getSurveysAction(patient.data?.id))
    } catch (error) {
      console.error(error)
      await produceState((draft) => {
        draft.sendUserSurveyStatus = 'error'
      })
    }
  }

export const resetCurrentFormSurveyAndFormPostAction =
  (): ContextAction => async (produceState) => {
    await produceState((draft) => {
      draft.currentFormPostId = undefined
      draft.currentFormPost = undefined
      draft.currentForm = undefined
      draft.currentSurvey = undefined
      draft.lastSeenQuestion = undefined
      draft.sendUserSurveyStatus = 'idle'
    })
  }

export const updateCurrentFormAndSurveyAction =
  (survey: Survey, form: Form): ContextAction =>
  async (produceState, getState) => {
    // formPost exist, form has been touched by user ✋🏻
    if (typeof form.formPostId === 'string') {
      await produceState((draft) => {
        draft.currentSurvey = survey
        draft.currentForm = form
        draft.currentFormPostId = form.formPostId
      })
    }
    // create form post (POST) for untouched form 😳
    else {
      const { user, patient, allForms, arrayOfFormPostOfToday } = getState()

      let apiFormPost: ApiFormPost
      if (user.data?.role === 'inspector') {
        apiFormPost = await SurveyService.createFormPost(
          form.id,
          patient.data?.id,
          user.data?.id,
        )
      } else {
        apiFormPost = await SurveyService.createFormPost(form.id)
      }

      const formPost: FormPost = mappings.fromApiFormPost(apiFormPost)
      const allFormsUpdated = allForms.data?.map((form) => {
        if (form.id === formPost.formId) {
          return { ...form, formPostId: formPost.id }
        }
        return form
      })

      const updatedForm = allFormsUpdated?.find(
        (form) => form.formPostId === formPost.id,
      )

      await produceState((draft) => {
        draft.allForms.data = allFormsUpdated
        draft.currentSurvey = survey
        draft.currentForm = updatedForm
        draft.currentFormPostId = formPost.id
        draft.arrayOfFormPostOfToday.data = [
          ...(arrayOfFormPostOfToday.data || []),
          formPost,
        ]
      })
    }
  }

export const updateSurveyStateAction =
  (updateSurveyState: SurveyState): ContextAction =>
  async (dispatch, getState) => {
    const { currentSurvey } = getState()
    if (currentSurvey) {
      const updatedSurvey: Survey = {
        ...currentSurvey,
        state: updateSurveyState,
      }
      // currentForm / currentSurvey will be set to undefined on next action trigger inside QA useEffect so we don't need to update theirs values
      //  we also need to update currentSurvey so navigation can occur
      await dispatch((draft) => {
        draft.currentSurvey = updatedSurvey
        draft.allForms.data = draft.allForms.data?.map((form) => {
          const updatedSurveys = form.surveys.map((survey) => {
            if (survey.id === updatedSurvey.id) {
              return updatedSurvey
            } else {
              return survey
            }
          })
          form.surveys = [...updatedSurveys]

          return form
        })
      })
    }
  }

export const getFormPostAction =
  (formPostId: string): ContextAction =>
  async (dispatch, getState) => {
    const { arrayOfFormPostOfToday } = getState()

    const currentFormPost = arrayOfFormPostOfToday.data?.find(
      (formPost) => formPost.id === formPostId,
    )
    await dispatch((draft) => {
      draft.currentFormPostId = currentFormPost?.id
      draft.currentFormPost = currentFormPost
    })
  }
