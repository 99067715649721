import {
  Card,
  CardContent,
  CardHeader,
  Container,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { ChuTheme } from '../../@types/theme'
import { settings } from '../../theme/settings'
import { AlertMessage } from './AlertMessage'

interface Props {
  header: string
  title: string
  subtitle?: string
  isError?: boolean
  errorTitle?: string
  errorMessage?: string
  hideError?: () => void
  isSuccess?: boolean
  successTitle?: string
  successMessage?: string
  hideSuccess?: () => void
  children: React.ReactNode
}

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: theme.palette.white,
    textAlign: 'center',
    padding: '50px 30px 30px 30px',
    overflowY: 'scroll',
  },
  card: {
    width: '100%',
    maxHeight: '100%',
    textAlign: 'center',
    overflowY: 'scroll',
  },
  cardContent: {
    backgroundColor: theme.palette.tertiaryColorLight,
    borderRadius: '55%/30px 30px 0 0',
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
  },
  connectButton: {
    color: theme.palette.white,
    '&:disabled': {
      background: theme.palette.grey,
    },
  },
  returnButton: {
    marginRight: 20,
  },
  errorContainer: {
    background: theme.palette.error.main,
  },
  title: {
    fontSize: '20px',
    color: theme.palette.primary.light,
    fontWeight: 500,
    margin: '10px -25px',
  },
  subtitle: {
    fontSize: '12px',
    color: theme.palette.info.dark,
    marginBottom: '10px',
  },
  img: {
    width: '180px',
  },
}))

/**
 * Layout autour d'un formulaire lié à l'authentification, e.g. sign in, forgotten password & request, create password
 */
export const AuthFormLayout: React.FC<Props> = ({
  header,
  title,
  subtitle,
  isError,
  errorTitle,
  errorMessage,
  hideError,
  isSuccess,
  successTitle,
  successMessage,
  hideSuccess,
  children,
}) => {
  const classes = useStyles()

  return (
    <Container className={classes.root}>
      <Card className={classes.card}>
        <CardHeader title={header} classes={{ title: classes.intro }} />
        <img
          src={settings.images.logo}
          alt="logo CHU"
          className={classes.img}
        />
        <CardContent className={classes.cardContent}>
          <Typography component="h2" className={classes.title}>
            {title}
          </Typography>

          {subtitle && (
            <Typography className={classes.subtitle}>{subtitle}</Typography>
          )}

          {isError && errorMessage && (
            <AlertMessage
              title={errorTitle}
              message={errorMessage}
              severity="error"
              autoClose={true}
              onClose={hideError}
              className={classes.error}
            />
          )}

          {isSuccess && successMessage && (
            <AlertMessage
              title={successTitle}
              message={successMessage}
              severity="success"
              autoClose={true}
              onClose={hideSuccess}
              className={classes.success}
            />
          )}

          {children}
        </CardContent>
      </Card>
    </Container>
  )
}
