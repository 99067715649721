import Snackbar from '@material-ui/core/Snackbar'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import React, { useContext } from 'react'
import { AppContext } from '../../context/app-context'

interface Props {
  id: string
  onClose: () => void
  persist?: boolean
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 600,
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    alert: {
      fontSize: '1.3rem',
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(14),
      },
    },
  }),
)

export const MessageToast = ({ id, onClose, persist = false }: Props) => {
  const classes = useStyles()
  const context = useContext(AppContext)
  const { isShown, style, message } = context.notifications[id]

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isShown}
        autoHideDuration={persist ? undefined : 4000}
        onClose={onClose}
      >
        <Alert severity={style} className={classes.alert} onClose={onClose}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}
