import { settings } from '../settings'

export default {
  containedSizeLarge: {
    padding: '20px 25px',
    color: `${settings.theme.palette.white}`,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.3,
  },
}
