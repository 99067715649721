import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import React, { useContext, useEffect, useState } from 'react'
import { ChuTheme } from '../../../@types/theme'
import { ReactComponent as LaunchIcon } from '../../../assets/icons/icon_launch.svg'
import i18n from '../../../assets/strings'
import { logoutAction } from '../../../context/actions/user'
import { AppContext } from '../../../context/app-context'
import { getDoctorsInfo } from '../../../services/auth.service'
import { settings } from '../../../theme/settings'
import { fromApiDoctor } from '../../../utils/mappings'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    height: 'inherit',
    textAlign: 'center',
    color: theme.palette.white,
    [theme.breakpoints.down('sm')]: {
      overflow: 'scroll',
    },
  },
  contentContainer: {
    padding: '50px 30px 30px 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'space-evenly',
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: '24px',
  },
  card: {
    background: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '8px',
  },
  cardMarginBottom: {
    marginBottom: '24px',
  },
  userInfoWrapper: {
    margin: '20px',
  },
  infoWrapper: {
    margin: '-20px 0 0 0',
  },
  hospitalInfo: {
    background: theme.palette.background.paper,
    borderRadius: '4px',
    padding: '30px 20px 20px 20px',
    marginTop: '-35px',
  },
  infosText: {
    fontSize: '13px',
    color: theme.palette.info.main,
  },
  infosDate: {
    fontSize: '16px',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  totalTimeToHospital: {
    background: theme.palette.secondary.main,
    borderRadius: '0 0 4px 4px',
    padding: '10px 25px',
  },
  cclText: {
    fontSize: '13px',
    color: theme.palette.white,
  },
  cclTime: {
    fontSize: '16px',
    color: theme.palette.white,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `solid 1px ${theme.palette.tertiaryColorMain}`,
    padding: '5px',
  },
  label: {
    width: '33.33%',
    textAlign: 'right',
    fontSize: '13px',
    color: theme.palette.tertiaryColorMain,
  },
  userInfo: {
    width: '66.66%',
    textAlign: 'left',
    fontSize: '16px',
    color: theme.palette.white,
    fontWeight: 600,
    paddingLeft: '20px',
  },
  doctorsTitleHeader: {
    fontSize: '16px',
    color: theme.palette.white,
    textAlign: 'left',
    backgroundColor: theme.palette.success.main,
  },
  doctorsTitle: {
    fontWeight: 600,
  },
  doctorName: {
    fontSize: '14px',
    color: theme.palette.white,
    fontWeight: 600,
  },
  logoutContainer: {
    marginBottom: theme.spacing(2),
  },
  logoutContainerInspector: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoutButton: {
    fontSize: 12,
  },
  logoutButtonInspector: {
    top: '50%',
    position: 'absolute',
  },
  logoBookContainer: {
    marginBottom: 16,
  },
  logoBook: {
    cursor: 'pointer',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '4px',
    width: 'max-content',
    textAlign: 'center',
  },
  logBookTitle: {
    fontSize: 16,
    textTransform: 'uppercase',
  },
  launchIcon: { fill: theme.palette.white },
}))

export const ProfilePage = () => {
  const classes = useStyles()
  const { dispatch, user, patient } = useContext(AppContext)
  const [doctorsInfosLoading, setDoctorsInfosLoading] =
    useState<DataState>('idle')
  const [doctorsInfos, setDoctorsInfos] = useState<Array<Doctor>>([])

  useEffect(() => {
    const getMyDoctorsInfos = async () => {
      if (patient.data === undefined) {
        return
      }

      setDoctorsInfosLoading('loading')

      const doctorsIds = patient.data.doctors
      if (!doctorsIds || doctorsIds.length < 1) {
        setDoctorsInfosLoading('error')
      }

      try {
        const UserDoctorsInfos = await getDoctorsInfo()
        const newDoctorsInfos = UserDoctorsInfos.map((doctor) =>
          fromApiDoctor(doctor),
        )
        setDoctorsInfos(newDoctorsInfos)
        setDoctorsInfosLoading('loaded')
      } catch (error) {
        setDoctorsInfosLoading('error')
      }
    }
    getMyDoctorsInfos()
  }, [patient.data])

  const handleLogout = () => {
    dispatch(logoutAction())
  }

  const logBookhandleClick = () => {
    window.open('https://icu-diary.lifemapp.one-o-one.eu', '_blank')
  }

  return (
    <Container className={classes.root}>
      {user.data?.role === 'patient' && (
        <Container className={classes.contentContainer}>
          <Typography component="h2" className={classes.title}>
            {settings.strings.profilePage.title}
          </Typography>
          {user?.data?.logBook === 'Oui' && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={classes.logoBookContainer}
            >
              <Grid
                className={classes.logoBook}
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
                onClick={logBookhandleClick}
                item
              >
                <Grid item>
                  <Typography className={classes.logBookTitle}>
                    {i18n.home.logBook}
                  </Typography>
                </Grid>
                <Grid item>
                  <LaunchIcon className={classes.launchIcon} />
                </Grid>
              </Grid>
            </Grid>
          )}

          <Card className={`${classes.card} ${classes.cardMarginBottom}`}>
            <Box className={classes.userInfoWrapper}>
              <Box className={classes.textWrapper}>
                <Typography component="p" className={classes.label}>
                  {settings.strings.profilePage.firstNameLabel}
                </Typography>
                <Typography component="h3" className={classes.userInfo}>
                  {patient.data?.lastName}
                </Typography>
              </Box>

              <Box className={classes.textWrapper}>
                <Typography component="p" className={classes.label}>
                  {settings.strings.profilePage.lastNameLabel}
                </Typography>
                <Typography component="h4" className={classes.userInfo}>
                  {patient.data?.firstName}
                </Typography>
              </Box>

              <Box className={classes.textWrapper}>
                <Typography component="p" className={classes.label}>
                  {settings.strings.profilePage.ageLabel}
                </Typography>
                <Typography component="h4" className={classes.userInfo}>
                  {patient.data?.birthDate} ans
                </Typography>
              </Box>
            </Box>

            <CardContent>
              <Box className={classes.infoWrapper}>
                <img src={settings.images.profilHospital} alt="hopital" />
                <Box className={classes.hospitalInfo}>
                  <Typography component="h3" className={classes.infosText}>
                    {settings.strings.profilePage.timeIn}
                  </Typography>
                  <Typography component="p" className={classes.infosDate}>
                    {patient.data?.entryDate}
                  </Typography>
                </Box>
              </Box>

              <Box className={classes.infoWrapper}>
                <img src={settings.images.profilHouse} alt="maison" />
                <Box className={classes.hospitalInfo}>
                  <Typography component="h3" className={classes.infosText}>
                    {settings.strings.profilePage.timeOut}
                  </Typography>
                  <Typography component="p" className={classes.infosDate}>
                    {patient.data?.releaseDate}
                  </Typography>
                </Box>
              </Box>
            </CardContent>

            <Box className={classes.totalTimeToHospital}>
              <Typography component="h3" className={classes.cclText}>
                {settings.strings.profilePage.totalTimeToHospital}
              </Typography>
              <Typography component="p" className={classes.cclTime}>
                {patient.data?.durationStay} jours
              </Typography>
            </Box>
          </Card>

          <Card className={classes.card}>
            <CardHeader
              title={settings.strings.profilePage.yourDoctors}
              classes={{
                root: classes.doctorsTitleHeader,
                title: classes.doctorsTitle,
              }}
            />

            {doctorsInfosLoading === 'loading' && (
              <Typography>loading...</Typography>
            )}
            {doctorsInfosLoading === 'error' && (
              <Typography>error...</Typography>
            )}
            {doctorsInfosLoading === 'loaded' && (
              <List dense>
                {doctorsInfos.map((doctor, i) => {
                  const doctorName = `${doctor.firstName || ''} ${
                    doctor.lastName || ''
                  }`
                  return (
                    <ListItem key={i}>
                      <ListItemAvatar>
                        <Avatar
                          alt={doctorName}
                          src="TODO: DOCTOR AVATAR OR ICON"
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={doctorName}
                        primaryTypographyProps={{
                          className: classes.doctorName,
                        }}
                      />
                    </ListItem>
                  )
                })}
              </List>
            )}
          </Card>
        </Container>
      )}

      <Container
        className={clsx(classes.logoutContainer, {
          [classes.logoutContainerInspector]: user.data?.role === 'inspector',
        })}
      >
        <Button
          onClick={handleLogout}
          variant="contained"
          color="primary"
          className={
            user.data?.role === 'inspector'
              ? classes.logoutButtonInspector
              : classes.logoutButton
          }
        >
          {settings.strings.profilePage.logout}
        </Button>
      </Container>
    </Container>
  )
}
