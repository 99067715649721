import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { ChuTheme } from '../../../@types/theme'
import fr from '../../../assets/strings/fr'
import { settings } from '../../../theme/settings'
import { Pagination } from './Pagination'

const onboardingSteps = [
  {
    title: fr.onboarding.firstStep.title,
    desc: fr.onboarding.firstStep.desc,
    imgPath: settings.images.stepOne,
  },
  {
    title: fr.onboarding.SecondStep.title,
    desc: fr.onboarding.SecondStep.desc,
    imgPath: settings.images.stepTwo,
  },
  {
    title: fr.onboarding.ThirdStep.title,
    desc: fr.onboarding.ThirdStep.desc,
    imgPath: settings.images.stepThree,
  },
  {
    title: fr.onboarding.FouthStep.title,
    desc: fr.onboarding.FouthStep.desc,
    imgPath: settings.images.stepFour,
  },
]

const useStyles = makeStyles<ChuTheme>((theme: any) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'auto',
    position: 'relative',
  },
  dialogRoot: {
    textAlign: 'center',
    padding: 0,
    flex: 'unset',
    overflowY: 'unset',
    '&:nth-child(1)': {
      paddingTop: 'unset',
    },
  },
  headerStep: {
    height: 400,
    [theme.breakpoints.down('sm')]: {
      height: 350,
    },
    width: '100%',
    position: 'absolute',
    overflow: 'hidden',
    zIndex: 1,
    '&::after': {
      content: "''",
      position: 'absolute',
      left: '-600%',
      width: '1300%',
      paddingBottom: '2000%',
      top: '80%',
      borderRadius: '50%',
      boxShadow:
        '0px 0 190px 200px rgba(0, 122, 255, 1), 0 0 8px 9900px  rgba(13, 49, 130, 1)',
      zIndex: -1,
    },
  },
  img: {
    width: 'auto',
    height: 333,
    [theme.breakpoints.down('sm')]: {
      height: 285,
    },
  },
  buttonsContainer: {
    position: 'absolute',
    height: 400,
    width: '100%',
    zIndex: 998,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    color: 'white',
    margin: '0 40px',
  },
  actionBar: {
    justifyContent: 'center',
    flexFlow: 'column',
    padding: '40px 25px',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  skip: {
    color: theme.palette.tertiaryColorMain,
    fontSize: 15,
    marginTop: '1.2rem',
    zIndex: 998,
  },
  title: {
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    fontSize: 19,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: '2rem',
  },
  description: {
    color: theme.palette.primary.dark,
    fontSize: 15,
  },
  closeButton: {
    zIndex: 999,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.success.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}))

export const SwipeableStepper = ({
  closeDialog,
}: {
  closeDialog: () => void
}) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = onboardingSteps.length

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  const handleChangeIndex = (index: any) => {
    setActiveStep(index)
  }

  return (
    <>
      <DialogContent className={classes.dialogRoot}>
        <Box className={classes.root}>
          <Box className={classes.headerStep}></Box>
          <SwipeableViews
            resistance
            index={activeStep}
            onChangeIndex={handleChangeIndex}
            style={{ zIndex: 400 }}
          >
            {Object.keys(onboardingSteps).map((_, currentIndex) => (
              <React.Fragment key={String(currentIndex)}>
                <img
                  className={classes.img}
                  src={onboardingSteps[currentIndex].imgPath}
                  alt={onboardingSteps[currentIndex].title}
                  style={{ zIndex: 200 }}
                />
                <Box mt={4} mb={2} mx="auto" width="75%">
                  <Typography component="h4" className={classes.title}>
                    {onboardingSteps[currentIndex].title}
                  </Typography>
                  <Typography component="p" className={classes.description}>
                    {onboardingSteps[currentIndex].desc}
                  </Typography>
                </Box>
              </React.Fragment>
            ))}
          </SwipeableViews>
        </Box>
      </DialogContent>
      <DialogActions className={classes.actionBar}>
        {activeStep === maxSteps - 1 ? (
          <Button
            variant="contained"
            size="large"
            className={classes.closeButton}
            onClick={() => closeDialog()}
          >
            {fr.onboarding.start}
          </Button>
        ) : (
          <>
            <Pagination
              dots={maxSteps}
              index={activeStep}
              onChangeIndex={handleChangeIndex}
            />
            <Link
              component="button"
              onClick={closeDialog}
              className={classes.skip}
              autoFocus
            >
              {fr.onboarding.skip}
            </Link>
          </>
        )}
      </DialogActions>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Box className={classes.buttonsContainer}>
          <Box className={classes.buttons}>
            <IconButton
              size="medium"
              onClick={handleBack}
              disabled={activeStep === 0}
              className={classes.button}
            >
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton
              size="medium"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
              className={classes.button}
            >
              <KeyboardArrowRight />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  )
}
