import { makeStyles, TextField, TextFieldProps } from '@material-ui/core'
import React from 'react'
import { ChuTheme } from '../../@types/theme'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  input: {
    fontSize: 16,
  },
  inputLabel: {
    fontSize: 16,
    padding: '8px 4px 4px 0',
    backgroundColor: theme.palette.white,
  },
  inputLabelFocused: {
    padding: '12px 4px 4px 0',
  },
}))

export const TextInput: React.FC<TextFieldProps> = (props) => {
  const classes = useStyles()

  return (
    <TextField
      variant="outlined"
      InputProps={{
        classes: {
          root: classes.input,
        },
      }}
      InputLabelProps={{
        classes: {
          root: classes.inputLabel,
          focused: classes.inputLabelFocused,
        },
      }}
      {...props}
    />
  )
}
