import { differenceInHours } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'

export const TIME_ZONE = 'Europe/Paris'
export const RESET_TIME = 1 // User can send its mood of the day between 01:00 and 00:59:59 of the next day

/**
 * Les patients ont jusqu'au lendemain 1:00 pour compléter leurs questionnaires
 * C'est aussi à 1:00 que l'humeur du jour change de jour
 * Cette fonction sert à savoir si deux dates sont dans le même créneau "J à partir de 1:00 - J+1 jusqu'à 1:00" (appelé AppDay)
 */
export const isSameAppDay = (date1: Date, date2: Date): boolean => {
  // Passage de la date dans la timezone donnée
  const zonedDate1 = toZonedTime(date1, TIME_ZONE)
  const zonedDate2 = toZonedTime(date2, TIME_ZONE)

  // 1. Si plus de 24h de différence entre les dates : pas possible que ce soit le même jour
  if (Math.abs(differenceInHours(date1, date2)) > 24) {
    return false
  }

  // 2. Si les dates sont le même jour, elles sont dans le même créneau à condition d'être toutes les deux avant 1:00 ou toutes les deux après 1:00
  const sameDay = zonedDate1.getDay() === zonedDate2.getDay()
  const date1BeforeReset = zonedDate1.getHours() < RESET_TIME
  const date2BeforeReset = zonedDate2.getHours() < RESET_TIME
  const bothBeforeOrAfterReset = date1BeforeReset === date2BeforeReset
  if (sameDay) {
    return bothBeforeOrAfterReset
  }

  // 3. Si les dates ne sont pas le même jours, elles sont ici forcément mois de 24h d'écart
  //    Si l'une des dates est après 1:00 et l'autre est avant 1:00, elles sont sur le même créneau
  if (
    (date1BeforeReset && !date2BeforeReset) ||
    (!date1BeforeReset && date2BeforeReset)
  ) {
    return true
  }

  return false
}
