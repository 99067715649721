import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ChuTheme } from '../../../../@types/theme'
import { AppContext } from '../../../../context/app-context'
import { settings } from '../../../../theme/settings'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    height: 'inherit',
    textAlign: 'center',
    padding: '50px 30px 30px 30px',
    color: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  user: {
    fontStyle: 'italic',
    fontSize: '18px',
    marginBottom: '10px',
  },
  intro: {
    fontSize: '16px',
    marginBottom: '10px',
  },
  span: {
    fontWeight: 600,
    fontStyle: 'italic',
  },
  conclusion: {
    fontSize: '15px',
  },
  button: {
    margin: '-17px 0 20px 0',
    width: '88%',
  },
}))

export const SurveyHomePage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { currentFormPost, currentSurvey, patient } = useContext(AppContext)

  const handleClickOnStartSurvey = () => {
    if (currentFormPost?.id && currentSurvey?.id) {
      navigate(`/form/${currentSurvey.id}/intro`)
    }
  }

  return (
    <Container className={classes.root}>
      <Typography className={classes.user}>
        {patient.data?.firstName || ''}
      </Typography>
      <Typography className={classes.intro}>
        {settings.strings.formHomepage.intro}
      </Typography>
      <img src={settings.images.formHomepage} alt="rappel rdv" />
      <Button
        className={classes.button}
        size="large"
        color="secondary"
        variant="contained"
        onClick={handleClickOnStartSurvey}
      >
        {currentSurvey && currentSurvey.totalNumberOfQuestions > 0
          ? `Je réponds aux ${currentFormPost?.totalQuestions} courtes questions`
          : 'Je réponds à plusieurs courtes questions'}
      </Button>

      <Typography className={classes.conclusion}>
        {settings.strings.formHomepage.letStart}
      </Typography>
    </Container>
  )
}
