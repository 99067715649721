import strings from '../../assets/strings'
import * as AuthApi from '../../services/auth.service'
import { getPatient } from '../../services/user.service'
import { isPasswordTooOld } from '../../utils/isPasswordTooOld'
import { isRoleAmong } from '../../utils/isRoleAmong'
import * as mappings from '../../utils/mappings'
import { isApiInspector, isApiPatient } from '../../utils/typeGuards'
import { ContextAction } from '../app-context'
import {
  getSurveysAction,
  resetCurrentFormSurveyAndFormPostAction,
} from './survey'

export enum UserRoleEnum {
  patient = 'patient',
  doctor = 'doctor',
  admin = 'admin',
  inspector = 'inspector',
}

export const loginAction =
  ({ username, password }: LoginFormValues): ContextAction =>
  async (produceState) => {
    await produceState((draft) => {
      draft.user.state = 'loading'
    })
    try {
      await AuthApi.loginUser({ username, password })
    } catch (error) {
      console.error(error)
      await produceState((draft) => {
        draft.user.state = 'error'
        draft.authError = strings.sign.login.errors.message
      })
    }
  }

/**
 * Get user infos and store them in context
 * @param userAuthInputs username, password or other inputs to log user
 */

export const fetchUserInfosAction =
  (): ContextAction => async (produceState, getState) => {
    await produceState((draft) => {
      draft.user.state = 'loading'
    })
    try {
      const { dispatch, patientId } = getState()
      const user = await AuthApi.getUserInfos()

      if (
        !isRoleAmong(user.role, [
          UserRoleEnum.admin,
          UserRoleEnum.patient,
          UserRoleEnum.inspector,
        ])
      ) {
        await dispatch(logoutAction())
        throw new Error('403')
      }

      // 1. Si l'utilisateur est un patient, on l'enregistre dans la clé 'patient' de l'app-context
      if (isApiPatient(user)) {
        await dispatch(getSurveysAction(user?._id))

        await produceState((draft) => {
          draft.user.data = mappings.fromApiAnyUser(user)
          draft.user.state = 'loaded'
          draft.patient.data = user ? mappings.fromApiPatient(user) : undefined
        })
      }

      // 2. Si l'utilisateur est un enquêteur, charge un patient en dur pour l'instant
      if (isApiInspector(user)) {
        if (patientId) {
          await dispatch(loadPatientAction())
          await dispatch(getSurveysAction(patientId))
        }

        await produceState((draft) => {
          draft.user.data = mappings.fromApiAnyUser(user)
          draft.user.state = 'loaded'
        })
      }
    } catch (error: unknown) {
      console.error(error)
      // if not already on sign in route, navigate to it since auth has failed and set original url in new url
      if (error instanceof Error) {
        await produceState((draft) => {
          draft.user.data = undefined
          draft.user.state = 'error'
          draft.authError =
            error.message === '403'
              ? strings.sign.login.errors.forbidden
              : strings.sign.login.errors.message
        })
      }
    }
  }

// updatefirstConnection
export const userFirstConnectionAction =
  (): ContextAction => async (produceState) => {
    try {
      await AuthApi.updatefirstConnection()
      await produceState((draft) => {
        draft.user.data = draft.user.data && {
          ...draft.user.data,
          firstConnection: false,
        }
      })
    } catch (error) {
      throw error
    }
  }

export const acceptDataCollectionAction =
  (): ContextAction => async (produceState, getState) => {
    const { dispatch } = getState()
    try {
      await AuthApi.acceptDataCollection()
    } catch (error) {
      throw error
    }
    await produceState(async (draft) => {
      if (draft.user.data) {
        draft.user.data.dataCollectionAccepted = true
      } else {
        await dispatch(fetchUserInfosAction())
      }
    })
  }

export const displayPasswordUpdateModalAction =
  (open?: boolean): ContextAction =>
  async (produceState, getState) => {
    if (open !== undefined) {
      await produceState((draft) => {
        draft.passwordUpdateModalOpen = open
      })
      return
    }

    // If no argument, determine depending on user data
    const { user } = getState()
    const passwordUpdateModalOpen = user.data?.lastPasswordUpdate
      ? isPasswordTooOld(user.data.lastPasswordUpdate)
      : false
    await produceState((draft) => {
      draft.passwordUpdateModalOpen = passwordUpdateModalOpen
    })
  }

export const logoutAction =
  (): ContextAction => async (produceState, getState) => {
    AuthApi.logoutUser()

    const { dispatch } = getState()
    produceState((draft) => {
      draft.user.data = undefined
      draft.user.state = 'idle'
      draft.patient.data = undefined
      draft.patient.state = 'idle'
      draft.patientId = undefined
    })
    // dispatch action to reset user survey data
    dispatch(resetCurrentFormSurveyAndFormPostAction())
  }

export const resetPasswordAction =
  (resetPasswordInputs: ResetPasswordInputs): ContextAction =>
  async (produceState) => {
    await produceState((draft) => (draft.resetPasswordStatus = 'idle'))
    try {
      //request reset password
      await AuthApi.forgottenPasswordReset(resetPasswordInputs)
      await produceState((draft) => {
        draft.resetPasswordStatus = 'success'
      })
    } catch (error) {
      await produceState((draft) => {
        draft.resetPasswordStatus = 'error'
      })
      throw error
    }
  }

export const createPasswordAction =
  (resetPasswordInputs: CreatePasswordInputs): ContextAction =>
  async (produceState) => {
    await produceState((draft) => (draft.createPasswordStatus = 'idle'))
    try {
      await AuthApi.createNewPassword(resetPasswordInputs)
      await produceState((draft) => {
        draft.createPasswordStatus = 'success'
      })
    } catch (error) {
      await produceState((draft) => {
        draft.createPasswordStatus = 'error'
      })
      throw error
    }
  }

// Enregistre l'id d'un patient dans le cas où l'utilisateur est un inspecteur qui va vouloir répondre à la place de ce patient
export const savePatientIdAction =
  (patientId: string): ContextAction =>
  async (produceState) => {
    await produceState((draft) => (draft.patientId = patientId))
  }

// Enregistre les informations d'un patient dans le cas où l'utilisateur est un inspecteur qui va vouloir répondre à la place de ce patient
export const loadPatientAction =
  (): ContextAction => async (produceState, useState) => {
    const { patientId } = useState()
    if (!patientId) {
      return
    }
    await produceState((draft) => {
      draft.patient.state = 'loading'
    })
    try {
      const patient = await getPatient(patientId)
      await produceState((draft) => {
        draft.patient.data = mappings.fromApiPatient(patient)
        draft.patient.state = 'loaded'
      })
    } catch (e) {
      await produceState((draft) => {
        draft.patient.state = 'error'
      })
      throw e
    }
  }
