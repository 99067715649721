export const isApiPatient = (user: ApiAnyUser): user is ApiPatient => {
  return user.role === 'patient'
}

export const isPatient = (user: AnyUser): user is Patient => {
  return user.role === 'patient'
}

export const isApiInspector = (user: ApiAnyUser): user is ApiInspector => {
  return user.role === 'inspector'
}

export const isInspector = (user: AnyUser): user is Inspector => {
  return user.role === 'inspector'
}
