import {
  Box,
  Button,
  Container,
  FormLabel,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { ChuTheme } from '../../../../@types/theme'
import { TextInput } from '../../../ui/TextInput'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    padding: 0,
    position: 'relative',
    overflowY: 'hidden',
  },
  subquestion: {
    fontSize: '16px',
    textAlign: 'center',
    color: theme.palette.info.dark,
    lineHeight: 1.2,
  },
  formLabel: {
    minHeight: '40px',
    backgroundColor: theme.palette.background.paper,
    borderBottom: `solid 1px ${theme.palette.lightAccentColor}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
  },
  question: {
    fontSize: '18px',
    textAlign: 'center',
    fontWeight: 500,
    color: theme.palette.white,
    lineHeight: 1.2,
    padding: '20px',
    backgroundColor: theme.palette.primary.main,
  },
  inputContainer: {
    padding: '24px 16px 24px 16px',
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    height: '90px',
    padding: '15px',
  },
  button: {
    margin: '5px',
  },
}))

interface QATextProps {
  question: Question
  handleChange: (arrayOfOneAnswer: Array<any>) => void
  nextButtonIsDisabled: boolean
  onNextButtonClick: () => void
  nextButtonLabel: string
}

export const QAText = ({
  question,
  handleChange,
  nextButtonIsDisabled,
  onNextButtonClick,
  nextButtonLabel,
}: QATextProps) => {
  const classes = useStyles()
  const [text, setText] = useState<string>('')

  const handleNextButtonClick = () => {
    setText('')
    onNextButtonClick()
  }

  return (
    <Container className={classes.root}>
      <Typography component="h3" className={classes.question}>
        {(question.isSubQuestion && question.instructions) || question.name}
      </Typography>

      {question.isSubQuestion && (
        <FormLabel component="legend" className={classes.formLabel}>
          <Typography className={classes.subquestion}>
            {question.isSubQuestion && question.name}
          </Typography>
        </FormLabel>
      )}

      <Box className={classes.inputContainer}>
        <TextInput
          label={question.answers[0]?.name}
          value={text}
          name={question.answers[0]?.name}
          onChange={(e) => {
            setText(e.target.value)
            handleChange([{ name: e.target.name, value: e.target.value }])
          }}
        />
      </Box>

      <div>
        <Box className={classes.inputWrapper}>
          <Button
            disabled={nextButtonIsDisabled || !question || text.length === 0}
            onClick={handleNextButtonClick}
            variant="contained"
            color="secondary"
            size="large"
            className={classes.button}
          >
            {nextButtonLabel}
          </Button>
        </Box>
      </div>
    </Container>
  )
}
