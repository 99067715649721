import Api from './api'

export async function createCarePathway(
  carePathway: ApiCarePathway,
): Promise<ApiCarePathway> {
  try {
    return await Api.post('/carePathway', { carePathway })
  } catch (e) {
    throw new Error('Could not send care pathway')
  }
}
