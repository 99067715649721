import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import { ChuTheme } from '../../@types/theme'

const useStyles = makeStyles<ChuTheme>(() => ({
  root: {},
}))
interface LayoutProps {
  children: React.ReactNode
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const classes = useStyles()
  return <Box className={classes.root}>{children}</Box>
}
