import * as FeedbackServices from '../../services/feedback.service'
import { ContextAction } from '../app-context'

export const sendFeedbackAction =
  (values: Feedback): ContextAction =>
  async (produceState) => {
    produceState((draft) => {
      draft.sendFeedbackStatus = 'loading'
    })

    try {
      await FeedbackServices.sendFeedback(values)
      await produceState((draft) => {
        draft.sendFeedbackStatus = 'loaded'
      })
    } catch (error) {
      console.error(error)
      produceState((draft) => {
        draft.sendFeedbackStatus = 'error'
      })
    }
  }
