import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import Save from '@material-ui/icons/Save'
import { Formik } from 'formik'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { ChuTheme } from '../../../@types/theme'
import { createCarePathwayAction } from '../../../context/actions/carePathway'
import { AppContext } from '../../../context/app-context'
import RadioToggleButtonGroup from '../../../forms/RadioToggleButtonGroup'
import { settings } from '../../../theme/settings'
import HospitalisationForm from './HospitalizationForm'
import { isHospitalization, supportTypes } from './utils'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  title: {
    fontSize: '16px',
    fontWeight: 500,
    margin: '12px 0',
    color: theme.palette.white,
  },
  type: {
    fontSize: '12px',
    color: theme.palette.white,
  },
  saveButton: {
    fontSize: '12px',
    width: 'max-content',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}))

const schema = Yup.object().shape({
  type: Yup.string().required(''),
  hospitalization: Yup.object().shape({
    status: Yup.string().required(''),
    date: Yup.string().required(''),
  }),
})

const CreateCarePathway = () => {
  const { dispatch, user, patient } = useContext(AppContext)
  const [showError, setShowError] = useState(false)
  const navigate = useNavigate()
  const onSubmit = async (values: CreateCarePathwayFormValues) => {
    try {
      createCarePathwayAction(values)
      navigate('/care-pathway')
    } catch (error) {
      setShowError(true)
    }
  }
  const classes = useStyles()
  return (
    <>
      <Formik<CreateCarePathwayFormValues>
        onSubmit={onSubmit}
        validationSchema={schema}
        initialValues={{
          type: 'Hospitalisation',
          hospitalization: {
            status: '',
            service: '',
            date: new Date().toISOString().split('T')[0],
            daysInHospital: '',
            comments: '',
          },
        }}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          dirty,
          isValid,
        }) => {
          console.log('aaaa', values)
          const submitDisabled = !dirty || isSubmitting || !isValid
          return (
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={4}></Grid>
                <Grid container item xs={4} direction="column">
                  <Grid item container justifyContent="center">
                    <Typography component="h2" className={classes.title}>
                      {settings.strings.carePathway.create.title}
                    </Typography>
                  </Grid>
                  <Grid item container>
                    <Typography component="h2" className={classes.type}>
                      {settings.strings.carePathway.create.type.title}
                    </Typography>
                  </Grid>
                  <Grid container item>
                    <RadioToggleButtonGroup
                      options={[
                        supportTypes.HOSPITALIZATION,
                        supportTypes.CONSULTATION,
                      ]}
                      labels={[
                        settings.strings.carePathway.create.type
                          .hospitalization,
                        settings.strings.carePathway.create.type.consultation,
                      ]}
                      name="type"
                      value={values.type}
                      onChange={(newValue) =>
                        newValue && setFieldValue('type', newValue)
                      }
                    />
                  </Grid>
                  {isHospitalization(values.type) && (
                    <HospitalisationForm values={values} />
                  )}
                  <Grid item container justifyContent="center">
                    <Button
                      endIcon={<Save className={classes.addIcon} />}
                      className={classes.saveButton}
                      disabled={submitDisabled}
                      type="submit"
                    >
                      {settings.strings.carePathway.save}
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            </form>
          )
        }}
      </Formik>
    </>
  )
}

export default CreateCarePathway
