import { Box, makeStyles, Typography } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { ChuTheme } from '../../../@types/theme'
import {
  getLastMoodAction,
  MoodValue,
  sendMoodAction,
} from '../../../context/actions/mood'
import { AppContext } from '../../../context/app-context'
import { settings } from '../../../theme/settings'
import { isSameAppDay } from '../../../utils/isSameAppDay'
import { CirleIcon } from './CirleIcon'

const useStyles = makeStyles<ChuTheme>((theme: any) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '375px',
    height: '100%',

    [theme.breakpoints.up('md')]: {
      background: '#2A48A7',
      paddingTop: theme.spacing(4),
    },
  },
  backgroundShape: {
    width: '300px',
    height: '174px',
    backgroundColor: theme.palette.backgroundShapeColor,
    borderRadius: '72% 28% 42% 58% / 53% 36% 64% 47% ', // cf. https://9elements.github.io/fancy-border-radius/
    marginBottom: '40px',
    boxShadow: `3px 3px 6px ${theme.palette.shadowColor}`,

    [theme.breakpoints.up('md')]: {
      width: '100%',
      height: '100%',
      borderRadius: '100% 28% 0% 0% / 27% 20% 0% 0%',
      marginTop: 60,
      marginBottom: 0,
      boxShadow: 'unset',
    },
  },
  myMoodContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: '14px',
    paddingRight: '14px',

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(4),
    },
  },
  myMoodImageAndTextContainer: {
    display: 'flex',
    marginBottom: '8px',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
  },
  myMoodImageContainer: {
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  myMoodTextContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingLeft: '7px',
    paddingBottom: '7px',
  },
  myMoodText: {
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'uppercase',
    lineHeight: 1.1,

    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
  },
  myMoodIconsContainer: {
    display: 'flex',
  },
}))

/**
 * Box with image & text & 3 icons for a patient to send its mood of the day (good, medium, bad)
 * Called in <Home />
 */
export const MoodBox = () => {
  const strings = settings.strings.moodBox
  const classes = useStyles()
  const { dispatch, lastMood } = useContext(AppContext)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const getLastMood = async () => {
      await dispatch(getLastMoodAction())
    }
    getLastMood()
  }, [dispatch])

  const handleMoodClick = async (moodValue: MoodValue) => {
    if (loading) {
      return
    }
    setLoading(true)
    await dispatch(sendMoodAction(moodValue))
    setLoading(false)
  }

  const isLastMoodOfToday = lastMood.data?.date
    ? isSameAppDay(new Date(lastMood.data.date), new Date())
    : false

  return (
    <Box className={classes.root}>
      <Box className={classes.backgroundShape} />

      <Box className={classes.myMoodContainer}>
        <Box className={classes.myMoodImageAndTextContainer}>
          <Box className={classes.myMoodImageContainer}>
            <img src={settings.images.moodPeople} alt="personnages" />
          </Box>
          <Box className={classes.myMoodTextContainer}>
            <Typography className={classes.myMoodText}>
              {strings.myMoodOfTheDay1}
              <br />
              {strings.myMoodOfTheDay2}
            </Typography>
          </Box>
        </Box>

        <Box className={classes.myMoodIconsContainer}>
          <CirleIcon
            imgSrc={settings.images.moodBad}
            imgAlt="pluie"
            onClick={() => handleMoodClick(MoodValue.Bad)}
            selected={
              isLastMoodOfToday && lastMood.data?.moodValue === MoodValue.Bad
            }
            disabled={loading}
          />
          <CirleIcon
            imgSrc={settings.images.moodMedium}
            imgAlt="nuage"
            onClick={() => handleMoodClick(MoodValue.Medium)}
            selected={
              isLastMoodOfToday && lastMood.data?.moodValue === MoodValue.Medium
            }
            disabled={loading}
          />
          <CirleIcon
            imgSrc={settings.images.moodGood}
            imgAlt="soleil"
            onClick={() => handleMoodClick(MoodValue.Good)}
            selected={
              isLastMoodOfToday && lastMood.data?.moodValue === MoodValue.Good
            }
            disabled={loading}
          />
        </Box>
      </Box>
    </Box>
  )
}
