import Api from './api'

export async function getLastMood(): Promise<ApiMood> {
  try {
    const response = await Api.get('/moods/my-last-mood')
    return response.data
  } catch (e) {
    throw new Error('Could not get last mood')
  }
}

export async function sendMood(moodValue: MoodValue): Promise<ApiMood> {
  try {
    return await Api.post('/moods', { moodValue })
  } catch (e) {
    throw new Error('Could not send mood')
  }
}
