const fr = {
  loading: {
    whileSigningIn: 'Chargement de votre compte',
  },
  sign: {
    login: {
      intro: "Bienvenue sur l'outil de suivi des patients",
      title: 'Merci de vous identifier',
      errors: {
        title: 'Une erreur est survenue',
        message: 'Vérifiez les champs puis réessayez',
        forbidden: "Vous n'êtes pas autorisé(e) à accéder à l'application",
        singleSession:
          "Une autre session a été ouverte avec votre compte, vous avez donc été déconnecté. Si vous n'avez pas ouvert de session récemment, veuillez changer votre mot de passe pour raison de sécurité.",
      },
      username: {
        label: 'Adresse e-mail',
        email: "L'adresse e-mail est invalide",
        required: "L'e-mail est requis",
      },
      password: {
        label: 'Mot de passe',
        required: 'Le mot de passe est requis',
      },
      forgottenPassword: {
        label: 'Mot de passe oublié ?',
      },
      button: 'Je me connecte',
    },
    forgottenPassword: {
      intro: "Bienvenue sur l'outil de suivi des patients",
      title: 'Mot de passe oublié',
      subtitle:
        'Utilisez de préférence au moins 8 caractères, un chiffre, et un caractère spécial',
      request: {
        errors: {
          title: 'Une erreur est survenue',
          message: 'Aucun utilisateur trouvé avec cette adresse e-mail',
        },
        email: {
          label: 'Adresse e-mail',
          email: "L'adresse e-mail est invalide",
          required: "L'e-mail est requis",
        },
        success: {
          title: "Succés de l'opération",
          message:
            'Un mail vient de vous être envoyé afin de poursuivre le processus',
        },
        button: 'Valider',
        return: 'retour',
      },
      reset: {
        errors: {
          title: 'Une erreur est survenue',
          message: "Le lien de n'est pas ou plus valide",
        },
        success: {
          title: "Succés de l'opération",
          message: 'Le mot de passe a été modifié avec succès',
        },
        password: {
          label: 'Mot de passe',
          required: 'Le mot de passe est requis',
        },
        confirmPassword: {
          label: 'Confirmation du mot de passe',
          required: 'La confirmation du mot de passe est requise',
        },
        notTheSamePassword: 'les deux mots de passe doivent être identiques',
        button: 'Valider',
        return: 'retour',
      },
    },
    createPassword: {
      checkingToken: {
        title: 'Vérification de la validité du lien...',
        description:
          "Ce lien est uniquement valide si il vient du message reçu lorsque vous avez été inscrit, et si vous n'avez pas encore créé de mot de passe",
      },
      invalidToken: {
        title: "Ce lien n'est pas valide",
        description:
          'Afin de créer votre mot de passe, cliquez sur le lien que vous avez reçu lorsque vous avez été inscrit. Si besoin, demandez à votre médecin de vous renvoyer un nouveau lien.',
      },
      errors: {
        title: 'Une erreur est survenue',
        message:
          "Le lien n'est pas valide, ou vous avez déjà un mot de passe. Assuez-vous de passer par le lien reçu dans votre email de bienvenue",
      },
      success: {
        title: "Succés de l'opération",
        message: 'Le mot de passe a été créé avec succès',
      },
    },
  },
  survey: {
    buttons: {
      next: 'Je valide ma réponse',
      previous: 'Question précédente',
      return: 'Retour',
      returnToDescription: 'Revoir la consigne',
      cancelAnswers: 'Supprimer mes réponses',
      sendAnswers: "J'envoie mes réponses",
      submit: 'Envoyer mes réponses',
      understood: "Ok, j'ai compris",
    },
  },
  underdevelopment: {
    label: 'Fonctionnalité en cours de développement',
  },
  confirmForm: {
    title: 'Êtes-vous sûre ?',
    warningText:
      'Si vous supprimez vos précédentes réponses, vous devrez recommencer le questionnaire dès le début.',
    cardText: 'Cette action est irréversible',
    buttons: {
      cancel: 'annuler la suppression',
      confirm: 'oui, je confirme',
    },
  },
  formHomepage: {
    intro:
      ' Nous avons besoin de vous pour effectuer le suivi de votre état de santé, à distance.',
    letStart: 'Prenez votre temps, installez-vous au calme, confortablement',
  },
  formDescription: {
    desc: 'Les questions qui suivent portent sur votre état de santé, telle que vous le ressentez. Veuillez répondre a toutes les questions en sélectionnant la réponse vous concernant. Si vous ne savez pas très bien comment répondre, choisissez la réponse la plus proche de votre situation.',
  },
  formResume: {
    resumeText:
      'Vous avez terminé le questionnaire. Voici le récapitulatif de vos réponses :',
  },
  endForm: {
    title:
      'Merci d’avoir pris le temps nécessaire à la préparation de votre prochain rendez-vous',
    text: 'Les réponses au questionnaire ont bien été envoyé à l’équipe médicale. Prenez soin de vous !',
    buttons: {
      close: 'fermer',
    },
  },
  validationConsult: {
    message: 'Votre demande de rendez-vous a bien été prise en compte',
    errorMessage: "Votre demande de rendez-vous n'a pas été transmise",
  },
  onboarding: {
    firstStep: {
      title: 'ensemble, menons à bien votre rétablissement',
      desc: 'Le but de cet outil de suivi à distance est de créer du lien entre vous et l’équipe médicale (les médecins du CHU ainsi que votre médecin traitant)',
    },
    SecondStep: {
      title: 'vous êtes la clés de réussite de cet outil de suivi !',
      desc: 'En l’utilisant quotidiennement, vous pourrez partager avec nous le niveau de votre moral général.',
    },
    ThirdStep: {
      title:
        'évaluez précisément votre niveau de santé en répondant aux différents questionnaires',
      desc: 'Plus vous répondrez, plus votre santé sera suivi avec précision. Un questionnaire commencé peut être poursuivi plus tard, dès lors que ce soit dans la même journée.',
    },
    FouthStep: {
      title: 'restons en contact !',
      desc: 'Posez-nous vos questions grâce à la messagerie instantanée ou demandez un échange par télé-consultation si vous en ressentez le besoin. Ensemble, retrouvons votre vie d’avant hospitalisation. Nous sommes là pour vous !',
    },
    start: 'je commence',
    skip: 'Passer les explications',
  },
  dataCollectionAcceptanceModal: {
    title: 'Utilisation des données pour la recherche scientifique',
    description:
      "Les réponses aux questionnaires seront collectées anonymement afin de faire avancer la recherche scientifique et d'améliorer le suivi des patients.",
    information:
      "L'acceptation de l'utilisation des données à des fins statistiques est obligatoire pour accéder à l'outil de suivi. Cliquer sur 'Refuser' vous déconnectera.",
    refuse: 'Refuser',
    accept: 'Accepter',
  },
  passwordUpdateWarningModal: {
    title: 'Mot de passe trop ancien',
    text: 'Votre mot de passe a été modifié pour la dernière fois il y a plus de 90 jours. Pour des raisons de sécurité, veuilliez le modifier en suivant le processus de réinitialisation de mot de passe.',
    ignore: 'Ignorer',
    toUpdatePassword: 'Mettre à jour',
  },
  calendar: {
    title:
      'Périodicité de vos 4 rendez-vous médicaux de suivi post-réanimation',
    outOfReanimation: 'Votre sortie de réanimation',
    checkUpTitle1Month: '1er Check-up',
    checkUpTitle3Month: '2e Check-up',
    checkUpTitle6Month: '3e Check-up',
    checkUpTitle1Year: '4e Check-up',
    checkUpToDo: 'Complétez au moins 1 Check-up avant le',
    checkUpCompleted: 'Check-up complété !',
  },
  validationDialog: {
    title:
      'Merci d’avoir pris le temps nécessaire à nous faire un retour sur cet outil de suivi.',
    content:
      'Votre commentaire a bien été envoyé à l’équipe technique et permettra d’améliorer l’outil.',
  },
  home: {
    header: {
      daysSinceOutOfHospitalSince: 'Vous êtes sorti de l’hôpital depuis',
      daysSinceOutOfHospitalDays: 'jours',
      weNeedYou:
        'Nous avons besoin de vous pour effectuer le suivi de votre état de santé, à distance.',
    },
    logBook: 'Mon journal de bord',
  },
  inspectorWithoutPatient: {
    title: 'Choisissez un patient',
    description:
      "Aucun patient n'est sélectionné. Afin de répondre aux formulaires d'un patient, veuillez passer par l'outil d'administration.",
  },
  legalNotice: {
    intro: "Bienvenue sur l'outil de suivi des patients",
    title: 'Mentions légales',
    presentation: {
      introductionBeforeUrl:
        'En vertu de l’article 6 de la loi n°2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, il est précisé aux utilisateurs du site',
      introductionAfterUrl:
        'l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi :',
      ownerTitle: 'Propriétaire',
      ownerContent: 'CHRU Hôpitaux de Tours – SIRET 26370018900016 – Tours',
      creatorTitle: 'Créateur',
      creatorContent: 'LA MOBILERY - http://lamobilery.fr/',
      publicationTitle: 'Responsable publication',
      publicationContent: 'LA MOBILERY',
      webmasterTitle: 'Webmaster',
      webmasterContent: 'Fabrice Bellotti – contact@lamobilery.fr',
      hostingTitle: 'Hébergeur',
      hostingContentAdress:
        'ATE – Avenir Télématique, 21 Avenue de la Créativité 59650 Villeneuve d’Ascq FRANCE',
      hostingContentContact: '+33 (0)3 28 800 300, contactweb@ate.info',
    },
  },
  footer: {
    homeLink: 'Accueil',
    legalNoticeLink: 'Mentions légales',
  },
}

export default fr
