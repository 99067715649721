import * as MoodApi from '../../services/mood.service'
import { sendMood } from '../../services/mood.service'
import { fromApiMood } from '../../utils/mappings'
import { ContextAction } from '../app-context'

// TODO: ALREADY DECLARED IN mood.d.ts, WHY DO WE HAVE TO DECLARE IT AGAIN?
export enum MoodValue {
  Good = 'good',
  Medium = 'medium',
  Bad = 'bad',
}

export const getLastMoodAction = (): ContextAction => async (produceState) => {
  await produceState((draft) => {
    draft.lastMood.state = 'loading'
  })
  try {
    const lastMood = await MoodApi.getLastMood()
    produceState((draft) => {
      draft.lastMood.data = fromApiMood(lastMood)
      draft.lastMood.state = 'loaded'
    })
  } catch (e) {
    produceState((draft) => {
      draft.lastMood.state = 'error'
    })
    console.error(e)
  }
}

export const sendMoodAction =
  (moodValue: MoodValue): ContextAction =>
  async (_, getState) => {
    const { dispatch } = getState()
    try {
      await sendMood(moodValue)
      await dispatch(getLastMoodAction())
    } catch (e) {
      console.error(e)
    }
  }
