import React, { useContext } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Calendar } from '../components/screens/Calendar/Calendar'
import CarePathwayList from '../components/screens/CarePathway/CarePathwayList'
import CreateCarePathway from '../components/screens/CarePathway/CreateCarePathway'
import { Contact } from '../components/screens/Contact/Contact'
import { CreatePassword } from '../components/screens/CreatePassword/CreatePassword'
import { ForgottenPassword } from '../components/screens/ForgottenPasword/ForgottenPassword'
import { Home } from '../components/screens/Home/Home'
import { InspectorWithoutPatient } from '../components/screens/InspectorWithoutPatient/InspectorWithoutPatient'
import { LegalNotice } from '../components/screens/LegalNotice/LegalNotice'
import { PageNotFound } from '../components/screens/PageNotFound/PageNotFound'
import { PatientId } from '../components/screens/PatientId/PatientId'
import { ProfilePage } from '../components/screens/Profile/Profile'
import { Sign } from '../components/screens/Sign/Sign'
import { Survey } from '../components/screens/Survey/Survey'
import { PageLayout } from '../components/ui/PageLayout'
import { AppContext } from '../context/app-context'
import { CustomRoute } from './CustomRoute'

const RootRouter = () => {
  const { user, patient, currentSurvey } = useContext(AppContext)
  const isAuthenticated = user.state === 'loaded'
  const { pathname } = useLocation()
  const redirectUrl =
    new URLSearchParams(window.location.search).get('url') || '/'

  return (
    <PageLayout isAuthenticated={isAuthenticated}>
      {/* keep sign Route above isAuthenticated redirection so that redirect can work */}
      {!isAuthenticated && (
        <Routes>
          <Route path="/sign" element={<CustomRoute element={<Sign />} />} />
          <Route
            path="/set-password/:token"
            element={<CustomRoute element={<CreatePassword />} />}
          />
          <Route
            path="/forgotten-password/:token"
            element={<CustomRoute element={<ForgottenPassword />} />}
          />
          <Route
            path="/forgotten-password"
            element={<CustomRoute element={<ForgottenPassword />} />}
          />
          <Route
            path="/patient/:patientId"
            element={<CustomRoute element={<PatientId />} />}
          />
          <Route
            path="/legal-notice"
            element={<CustomRoute element={<LegalNotice />} />}
          />
          <Route
            path="*"
            element={<Navigate to={`/sign?url=${pathname || '/'}`} replace />}
          />
        </Routes>
      )}
      {isAuthenticated && (
        <Routes>
          <Route
            path="/patient/not-found"
            element={<CustomRoute element={<InspectorWithoutPatient />} />}
          />
          <Route
            path="/patient/:patientId"
            element={<CustomRoute element={<PatientId />} />}
          />

          {user.data?.role === 'inspector' && !patient.data && (
            <Route
              path="/"
              element={<Navigate to="/patient/not-found" replace />}
            />
          )}
          <Route path="/" element={<CustomRoute element={<Home />} />} />
          <Route
            path="/profile"
            element={<CustomRoute element={<ProfilePage />} />}
          />
          <Route
            path="/form/:surveyId/*"
            element={<CustomRoute element={<Survey />} />}
          />
          <Route
            path="/calendar"
            element={<CustomRoute roles={['patient']} element={<Calendar />} />}
          />
          <Route
            path="/care-pathway"
            element={
              <CustomRoute roles={['patient']} element={<CarePathwayList />} />
            }
          />
          <Route
            path="/care-pathway/create"
            element={
              <CustomRoute
                roles={['patient']}
                element={<CreateCarePathway />}
              />
            }
          />
          <Route
            path="/contact/*"
            element={<CustomRoute element={<Contact />} />}
          />
          <Route
            path="/legal-notice"
            element={<CustomRoute element={<LegalNotice />} />}
          />
          <Route
            path="/not-found"
            element={<CustomRoute element={<PageNotFound />} />}
          />
          <Route
            path="/sign"
            element={
              <Navigate
                to={redirectUrl.startsWith('/') ? redirectUrl : '/'}
                replace
              />
            }
          />
          <Route path="*" element={<Navigate to="/not-found" replace />} />
        </Routes>
      )}
    </PageLayout>
  )
}

export default RootRouter
