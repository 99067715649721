import { makeStyles } from '@material-ui/core/styles'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { useField } from 'formik'
import React from 'react'

interface Props {
  name: string
  options: string[]
  labels: string[]
  onChange: (newValue: string | null) => void
  value: string | undefined
}

const useStyles = makeStyles((theme) => ({
  toggleGroup: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  toggleButton: {
    flex: 1,
    padding: theme.spacing(2),
    borderRadius: '8px',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}))

const RadioToggleButtonGroup = ({
  name,
  value,
  onChange,
  options,
  labels,
}: Props) => {
  const classes = useStyles()

  const [field] = useField(name)

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string,
  ) => {
    if (newValue !== null) {
      onChange(newValue)
    }
  }

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={handleToggle}
      className={classes.toggleGroup}
    >
      {options.map((option, index) => (
        <ToggleButton
          key={option}
          value={option}
          className={classes.toggleButton}
        >
          {labels[index]}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

export default RadioToggleButtonGroup
