import { createCarePathway } from '../../services/carePathway.service'
import { ContextAction } from '../app-context'

export const createCarePathwayAction =
  (carePathway: ApiCarePathway): ContextAction =>
  async (_, getState) => {
    const { dispatch } = getState()
    try {
      await createCarePathway(carePathway)
    } catch (e) {
      console.error(e)
    }
  }
