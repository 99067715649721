import {
  addMonths,
  addYears,
  differenceInDays,
  differenceInYears,
  format,
} from 'date-fns'
import { fr } from 'date-fns/locale'
import { SurveyStateEnum } from '../context/actions/survey'
import { getTotalOfQuestionsOfForm } from './getTotalOfQuestionsOfForm'

/**
 * Create a usable survey for Form components
 * @param allSurveysWithoutQuestions
 * @param allQuestions
 * @param demo demo purpose
 */

export enum ApiQuestionTypeEnum {
  multiplechoice = 'multiple-choice',
  singlechoice = 'single-choice',
  top = 'top',
}

export enum ApiMessageTypeEnum {
  appointment = 'appointment',
}

export enum FormPostStateEnum {
  notstarted = 'not-started',
  inprogress = 'in-progress',
  completed = 'completed',
}

const formatAnswer = (apiAnswer: ApiPossibleAnswer): Answer => ({
  name: apiAnswer.label,
  value: apiAnswer.value,
  sliderMin: apiAnswer.sliderMin,
  sliderMax: apiAnswer.sliderMax,
  sliderStep: apiAnswer.sliderStep,
})

const formatFlatQuestion = (
  question: ApiQuestion,
  apiSurvey: ApiSurvey,
): Question => {
  return {
    id: question._id,
    manyResponses:
      question.type === ApiQuestionTypeEnum.multiplechoice ? true : false,
    type: question.type,
    answers: question.possibleAnswers.map(formatAnswer),
    userAnswers: [],
    name:
      question.label && question.label.length === 0
        ? apiSurvey.description
        : question.label, // if ø question name, add the one from survey,
  }
}

const formatSubQuestion = (question: ApiQuestion) => {
  return question.subQuestions.map<Question>((subquestion) => ({
    id: subquestion._id,
    manyResponses:
      question.type === ApiQuestionTypeEnum.multiplechoice ? true : false,
    type: subquestion.type,
    answers: subquestion.possibleAnswers.map(formatAnswer),
    userAnswers: [],
    isSubQuestion: true,
    name: subquestion.label,
    instructions: question.label || undefined,
  }))
}

// If flat question, handle questions with no name. If subquestion, add instructions (question.description) and question's answers to each
const formatQuestions = (
  arrayOfApiQuestions: Array<ApiQuestion>,
  apiSurvey: ApiSurvey,
) => {
  return arrayOfApiQuestions.reduce<Array<Question>>(
    (arrayOfQuestions, question) => {
      // subquestion
      if (question.subQuestions && question.subQuestions.length > 0) {
        return arrayOfQuestions.concat(formatSubQuestion(question))
      }

      // flat
      arrayOfQuestions.push(formatFlatQuestion(question, apiSurvey))
      return arrayOfQuestions
    },
    [],
  )
}

const formatSurveys = (arrayOfSurveys: Array<ApiSurvey>) => {
  return arrayOfSurveys.map<Survey>((apiSurvey) => {
    const arrayOfFormattedQuestions = formatQuestions(
      apiSurvey.questions,
      apiSurvey,
    )
    return {
      id: apiSurvey._id,
      name: apiSurvey.name,
      description: apiSurvey.description,
      questions: arrayOfFormattedQuestions,
      totalNumberOfQuestions: arrayOfFormattedQuestions.length,
    }
  })
}

export const fromApiForms = (
  allFormsWithSurveyApi: Array<ApiFormWithSurvey>,
): Array<FormUntouched> => {
  const data = allFormsWithSurveyApi.reduce<Array<FormUntouched>>(
    (arrayOfForms, apiForm) => {
      arrayOfForms.push({
        id: apiForm._id,
        name: apiForm.name,
        thematic: {
          label: apiForm.thematic.label,
          background: apiForm.thematic.background,
        },
        icon: apiForm.icon,
        surveys: formatSurveys(apiForm.survey),
      })
      return arrayOfForms
    },
    [],
  )
  return data
}

export const userSurveyToAnswers = (
  userSurvey: Survey,
): Array<ApiFormPostUPDATEAnswer> => {
  const answers = userSurvey.questions.reduce<Array<ApiFormPostUPDATEAnswer>>(
    (userAnswers, qst) => {
      userAnswers.push({
        questionId: qst.id,
        values: qst.userAnswers.map((answ) => answ.value),
        date: new Date(),
      })
      return userAnswers
    },
    [],
  )
  return answers
}

export function fromApiAnyUser(user: ApiAnyUser): AnyUser {
  return {
    id: user._id,
    role: user.role,
    codeId: user.codeId,
    username: user.username,
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    birthDate: user.birthDate
      ? differenceInYears(Date.now(), new Date(user.birthDate))
      : 0,
    inHospitalDate: user.inHospitalDate
      ? new Date(user.inHospitalDate)
      : undefined,
    entryDate: user.inHospitalDate
      ? format(new Date(user.inHospitalDate), 'dd/MM/yyyy')
      : '',
    outHospitalDate: user.outHospitalDate
      ? new Date(user.outHospitalDate)
      : undefined,
    releaseDate: user.outHospitalDate
      ? format(new Date(user.outHospitalDate), 'dd/MM/yyyy')
      : '',
    phoneNumber: user.phoneNumber,
    durationStay:
      user.outHospitalDate && user.inHospitalDate
        ? differenceInDays(
            new Date(user.outHospitalDate),
            new Date(user.inHospitalDate),
          )
        : undefined,
    doctors: user.doctors,
    firstConnection: user.firstConnection,
    dataCollectionAccepted: user.dataCollectionAccepted,
    lastPasswordUpdate: user.lastPasswordUpdate
      ? new Date(user.lastPasswordUpdate)
      : undefined,
    lastConnections: user.lastConnections
      ? user.lastConnections.map((dateString) => new Date(dateString))
      : [],
    logBook: user?.logBook,
  }
}

export function fromApiPatient(patient: ApiPatient): Patient {
  return {
    id: patient._id,
    role: patient.role,
    codeId: patient.codeId,
    username: patient.username,
    firstName: patient.firstName || '',
    lastName: patient.lastName || '',
    activatedAt: patient.activatedAt
      ? new Date(patient.activatedAt)
      : undefined,
    birthDate: patient.birthDate
      ? differenceInYears(Date.now(), new Date(patient.birthDate))
      : 0,
    inHospitalDate: patient.inHospitalDate
      ? new Date(patient.inHospitalDate)
      : undefined,
    entryDate: patient.inHospitalDate
      ? format(new Date(patient.inHospitalDate), 'dd/MM/yyyy')
      : '',
    outHospitalDate: patient.outHospitalDate
      ? new Date(patient.outHospitalDate)
      : undefined,
    releaseDate: patient.outHospitalDate
      ? format(new Date(patient.outHospitalDate), 'dd/MM/yyyy')
      : '',
    phoneNumber: patient.phoneNumber,
    durationStay:
      patient.outHospitalDate && patient.inHospitalDate
        ? differenceInDays(
            new Date(patient.outHospitalDate),
            new Date(patient.inHospitalDate),
          )
        : undefined,
    doctors: patient.doctors,
    firstConnection: patient.firstConnection,
    dataCollectionAccepted: patient.dataCollectionAccepted,
    lastPasswordUpdate: patient.lastPasswordUpdate
      ? new Date(patient.lastPasswordUpdate)
      : undefined,
    lastConnections: patient.lastConnections
      ? patient.lastConnections.map((dateString) => new Date(dateString))
      : [],
    logBook: patient?.logBook,
  }
}

export function fromApiDoctor(doctor: ApiDoctor): Doctor {
  return {
    id: doctor._id,
    role: doctor.role,
    firstName: doctor.firstName,
    lastName: doctor.lastName,
    email: doctor.email,
  }
}

export function fromApiMood(apiMood: ApiMood): Mood {
  return {
    id: apiMood._id,
    date: apiMood.date,
    userId: apiMood.userId,
    moodValue: apiMood.moodValue,
  }
}

export function fromApiFormPost(apiFormPost: ApiFormPost): FormPost {
  return {
    id: apiFormPost._id,
    formId: apiFormPost.formId,
    patientId: apiFormPost.patientId,
    date: apiFormPost.date,
    totalQuestions: apiFormPost.totalQuestions,
    totalQuestionsAnswered: apiFormPost.totalQuestionsAnswered,
    state: apiFormPost.state,
    surveys: formatSurveys(apiFormPost.questionnaires),
  }
}

export function fromApiFormPostOfToday(
  arrayOfApiFormPost: Array<ApiFormPost>,
): Array<FormPost> {
  return arrayOfApiFormPost.reduce<Array<FormPost>>(
    (arrayOfFormPostOfToday, apiFormPostOfToday) => {
      arrayOfFormPostOfToday.push({
        id: apiFormPostOfToday._id,
        formId: apiFormPostOfToday.formId,
        patientId: apiFormPostOfToday.patientId,
        date: apiFormPostOfToday.date,
        totalQuestions: apiFormPostOfToday.totalQuestions,
        totalQuestionsAnswered: apiFormPostOfToday.totalQuestionsAnswered,
        state: apiFormPostOfToday.state,
        surveys: formatSurveys(apiFormPostOfToday.questionnaires),
      })
      return arrayOfFormPostOfToday
    },
    [],
  )
}

export function fromFormUntouchedToForm(
  nakedForm: FormUntouched,
  formPost: FormPost,
) {
  return {
    id: nakedForm.id,
    formPostId: formPost.id,
    name: nakedForm.name,
    thematic: nakedForm.thematic,
    icon: nakedForm.icon,
    state: formPost.state,
    surveys:
      formPost.state === FormPostStateEnum.completed
        ? nakedForm.surveys.map((survey) => ({
            ...survey,
            state: SurveyStateEnum.completed,
          }))
        : nakedForm.surveys,
    totalQuestions: formPost.totalQuestions,
    totalQuestionsAnswered: formPost.totalQuestionsAnswered,
    date: formPost.date,
  }
}

export function fromArrayFormUntouchedToArrayForm(
  arrayOfNakedForm: Array<FormUntouched>,
  arrayOfFormPostOfToday: Array<FormPost>,
): Array<Form> {
  return arrayOfNakedForm.reduce<Array<Form>>((allForms, nakedForm) => {
    if (arrayOfFormPostOfToday.length > 0) {
      // ici
      for (const formPost of arrayOfFormPostOfToday) {
        const formWithSameFormPostId = allForms.some(
          (frm) => frm.id === formPost.formId,
        )

        const existingNakedFormWithSameFormId = allForms.some(
          (frm) => frm.id === nakedForm.id,
        )

        if (formPost.formId === nakedForm.id && !formWithSameFormPostId) {
          allForms.push(fromFormUntouchedToForm(nakedForm, formPost))
        } else if (formPost.formId === nakedForm.id) {
          const formToUpdateWithFormPostInfo = allForms.find(
            (frm) => frm.id === formPost.formId,
          )
          const index = allForms.findIndex((frm) => frm.id === formPost.formId)
          if (formToUpdateWithFormPostInfo) {
            allForms[index] = fromFormUntouchedToForm(
              formToUpdateWithFormPostInfo,
              formPost,
            )
          }
        }
        // if not already added, add it
        else if (
          formPost.formId !== nakedForm.id &&
          !existingNakedFormWithSameFormId
        ) {
          allForms.push({
            id: nakedForm.id,
            formPostId: null,
            name: nakedForm.name,
            thematic: nakedForm.thematic,
            icon: nakedForm.icon,
            state: FormPostStateEnum.notstarted,
            surveys: nakedForm.surveys,
            totalQuestions: getTotalOfQuestionsOfForm(nakedForm),
            totalQuestionsAnswered: 0,
            date: new Date().toISOString(),
          })
        }
      }

      return allForms
    } else {
      allForms.push({
        id: nakedForm.id,
        formPostId: null,
        name: nakedForm.name,
        thematic: nakedForm.thematic,
        icon: nakedForm.icon,
        state: FormPostStateEnum.notstarted,
        surveys: nakedForm.surveys,
        totalQuestions: getTotalOfQuestionsOfForm(nakedForm),
        totalQuestionsAnswered: 0,
        date: new Date().toISOString(),
      })

      return allForms
    }
  }, [])
}

function fromScheduledFor(
  scheduledFor: ScheduledFor | undefined,
  startDate: Date,
): CalendarMilestone {
  let deadline = new Date(startDate)
  let completed = false
  if (scheduledFor?.months) {
    deadline = addMonths(deadline, scheduledFor?.months.numberOf)
    completed = completed || scheduledFor.months.completed
  }
  if (scheduledFor?.years) {
    deadline = addYears(deadline, scheduledFor?.years.numberOf)
    completed = completed || scheduledFor?.years.completed
  }
  return {
    completed,
    deadline,
  }
}

export function fromApiCalendar(
  calendar: ApiCalendar,
  activatedAt: Date | undefined,
): Calendar {
  const startDate = activatedAt || new Date()

  const scheduledFor1Month = calendar.scheduledFor.find(
    (item) => item.months?.numberOf === 1,
  )
  const scheduledFor3Month = calendar.scheduledFor.find(
    (item) => item.months?.numberOf === 3,
  )
  const scheduledFor6Month = calendar.scheduledFor.find(
    (item) => item.months?.numberOf === 6,
  )
  const scheduledFor1Year = calendar.scheduledFor.find(
    (item) => item.years?.numberOf === 1,
  )

  return {
    name: calendar.name,
    '1Month': fromScheduledFor(scheduledFor1Month, startDate),
    '3Months': fromScheduledFor(scheduledFor3Month, startDate),
    '6Months': fromScheduledFor(scheduledFor6Month, startDate),
    '1Year': fromScheduledFor(scheduledFor1Year, startDate),
  }
}

export function fromApiNextAppointment(
  appointment: ApiAppointment[],
): NextAppointment | undefined {
  if (appointment.length > 0) {
    return {
      appointmentDate: format(
        new Date(appointment[0].appointmentDate),
        `	iii dd/MM à HH'H'mm`,
        { locale: fr },
      ),
    }
  }
}
