import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ChuTheme } from '../../../@types/theme'
import { AppContext } from '../../../context/app-context'
import { settings } from '../../../theme/settings'
import { groupByMonthAndYear } from './utils'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {},
  title: {
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: '12px',
    color: theme.palette.white,
  },
  titleDescription: {
    fontSize: '14px',
    marginBottom: '12px',
    color: theme.palette.white,
  },
  listContainer: {
    // padding: '5px 8px',
    // maxHeight: '400px',
    // overflowY: 'scroll',
  },
  listItem: {
    border: `1px solid ${theme.palette.white}`,
    marginBottom: '6px',
    padding: '10px 20px',
    color: theme.palette.white,
  },
  listItemText: {
    fontSize: '18px',
    color: theme.palette.white,
  },
  addButton: {
    fontSize: '12px',
    width: 'max-content',
    backgroundColor: theme.palette.secondary.main,
    padding: '12px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  addButtonLink: {
    textDecoration: 'none',
    color: theme.palette.white,
  },
  addIcon: {
    fill: theme.palette.white,
    border: `1px solid ${theme.palette.white}`,
  },
}))

const CarePathwayList = () => {
  const classes = useStyles()
  const { dispatch, user, patient } = useContext(AppContext)
  const testList = [
    { type: 'Consultation', date: new Date(2021, 5, 22) },
    { type: 'Hospitalisation', date: new Date(2023, 12, 13) },
    { type: 'Hospitalisation', date: new Date(2024, 1, 1) },
    { type: 'Consultation', date: new Date(2024, 1, 22) },
    { type: 'Consultation', date: new Date(2023, 12, 25) },
    { type: 'Consultation', date: new Date(2023, 12, 1) },
    { type: 'Hospitalisation', date: new Date(2023, 2, 10) },
    { type: 'Hospitalisation', date: new Date(2022, 2, 10) },
  ]
  const groupedItems = groupByMonthAndYear(testList)
  return (
    <Grid
      className={classes.root}
      container
      justifyContent="center"
      direction="column"
    >
      <Grid item container justifyContent="center">
        <Grid item container justifyContent="center">
          <Typography component="h2" className={classes.title}>
            {settings.strings.carePathway.title}
          </Typography>
        </Grid>
        <Grid item container justifyContent="center">
          <Typography component="h2" className={classes.titleDescription}>
            {settings.strings.carePathway.description}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={4}></Grid>
        <Grid xs={4} item container direction="column">
          <Grid
            item
            container
            direction="column"
            className={classes.listContainer}
            wrap="nowrap"
          >
            {Object.entries(groupedItems).map(([monthYear, items]) => (
              <React.Fragment key={monthYear}>
                <Grid container justifyContent="center">
                  <Typography variant="h6" className={classes.listItemText}>
                    {monthYear}
                  </Typography>
                </Grid>
                {items.map((el, id) => (
                  <Grid
                    key={id}
                    item
                    container
                    justifyContent="space-between"
                    className={classes.listItem}
                  >
                    <Grid className={classes.listItemText}>{el.type}</Grid>
                    <Grid className={classes.listItemText}>
                      {format(el.date, 'd MMMM yyyy', { locale: fr })}
                    </Grid>
                  </Grid>
                ))}
              </React.Fragment>
            ))}
          </Grid>
          <Button
            startIcon={<Add className={classes.addIcon} />}
            className={classes.addButton}
          >
            <Link to="/care-pathway/create" className={classes.addButtonLink}>
              {settings.strings.carePathway.addButtonTitle}
            </Link>
          </Button>
        </Grid>
        <Grid xs={4}></Grid>
      </Grid>
    </Grid>
  )
}

export default CarePathwayList
