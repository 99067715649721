import { Box, makeStyles, MobileStepper } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ChuTheme } from '../../../../@types/theme'
import strings from '../../../../assets/strings'
import {
  sendUserSurveyAction,
  setLastSeenQuestionAction,
  SurveyStateEnum,
  updateSurveyStateAction,
  updateUserAnswersAction,
} from '../../../../context/actions/survey'
import { AppContext } from '../../../../context/app-context'
import { areAllOtherSurveysCompleted } from '../../../../utils/areAllOtherSurveysCompleted'
import { QAMultipleChoice } from './QAMultipleChoice'
import { QASingleChoice } from './QASingleChoice'
import { QASlider } from './QASlider'
import { QAText } from './QAText'

interface Props {
  survey: Survey
  currentForm: Form
}

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    height: 'inherit',
    padding: 0,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      overflow: 'scroll',
    },
  },
  stepper: {
    color: theme.palette.mainAccentColor,
    fontSize: '1Opx',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 500,
  },
  progress: {
    background: 'transparent',
  },
  questionBox: {
    paddingTop: '50px',
    backgroundColor: theme.palette.primary.main,
  },
}))

export const UIQA: React.FC<Props> = ({ survey, currentForm }) => {
  const { surveyId } = useParams<{ surveyId: string }>()
  const navigate = useNavigate()
  const { dispatch, sendUserSurveyStatus, lastSeenQuestion } =
    useContext(AppContext)

  const totalNumberOfQuestions = currentForm.totalQuestions
  const surveyNumberOfQuestions = survey.totalNumberOfQuestions

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)

  const questionsStep = lastSeenQuestion?.currentStep ?? 1

  const currentQuestion = survey.questions[currentQuestionIndex]

  useEffect(() => {
    if (
      (sendUserSurveyStatus === 'updated' ||
        survey.state === SurveyStateEnum.completed) &&
      areAllOtherSurveysCompleted(currentForm.surveys)
    ) {
      const triggerEndOfSurvey = async () => {
        navigate(`/form/${surveyId}/end`)
      }
      triggerEndOfSurvey()
    }
  }, [sendUserSurveyStatus, survey.state])

  const addUserAnswer = (questionWithUserAnswers: Question) => {
    dispatch(updateUserAnswersAction(questionWithUserAnswers))
  }

  // * Show description before answers (or not)
  const hasDescription =
    survey.description && survey.description?.length > 0 ? true : false

  const classes = useStyles()

  // * Next Buttons Logic
  const [nextButtonIsDisabled, setNextButtonIsDisabled] = useState(false)
  const [nextButtonLabel, setNextButtonLabel] = useState(
    strings.survey.buttons.next,
  )

  useEffect(() => {
    if (sendUserSurveyStatus === 'loading') {
      setNextButtonIsDisabled(true)
    } else if (nextButtonIsDisabled) {
      setNextButtonIsDisabled(false)
    }
  }, [sendUserSurveyStatus, nextButtonIsDisabled])

  // labels handlings
  useEffect(() => {
    // this is the end or start of the survey, switch labels
    if (questionsStep === totalNumberOfQuestions) {
      setNextButtonLabel(strings.survey.buttons.submit)
    }
    // if this is not the last question,
    if (
      questionsStep < totalNumberOfQuestions &&
      nextButtonLabel === strings.survey.buttons.submit
    ) {
      setNextButtonLabel(strings.survey.buttons.next)
    }
  }, [questionsStep, hasDescription, nextButtonLabel, totalNumberOfQuestions])

  /**
   *
   * Not-end-of-survey flow :
   * - store user's answer in parent's state
   * - increment stepper step (not dumb and dumber)
   * - increment currentStep (high order question) or currentSubQuestion
   * - reset userAnswer to a default state so next question has first answer as default answer
   *
   * End of survey :
   * - send user survey
   * @param userAnswer
   */

  const onNextButtonClick = async () => {
    await dispatch(
      setLastSeenQuestionAction({
        currentStep: questionsStep + 1,
      }),
    )

    if (currentQuestionIndex + 1 < surveyNumberOfQuestions) {
      setCurrentQuestionIndex((index) => index + 1)
      return
    }

    // Remise à 0 du compteur de question.
    setCurrentQuestionIndex(0)
    await dispatch(updateSurveyStateAction(SurveyStateEnum.completed))

    if (areAllOtherSurveysCompleted(currentForm.surveys)) {
      // Envoi des réponses à l'API
      const surveys = currentForm.surveys.map((localSurvey) =>
        localSurvey.id === survey.id ? survey : localSurvey,
      )
      await dispatch(sendUserSurveyAction(surveys))
      return
    }

    // Passage au questionnaire suivant
    const surveyIndex = currentForm.surveys.findIndex(
      (item) => item.id === survey.id,
    )
    const nextSurvey = currentForm.surveys[surveyIndex + 1]
    if (nextSurvey) {
      navigate(`/form/${nextSurvey.id}/intro`)
    }
  }

  // scroll to top of the form when switching question / subquestion, in order to clearly see subquestion
  const topOfFormRef = useRef<any>(null)
  useEffect(() => {
    if (topOfFormRef && topOfFormRef.current) {
      topOfFormRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [currentQuestion.id])

  const handleChange = (arg: Array<Answer>) => {
    const questionWithUserAnswers: Question = {
      ...currentQuestion,
      userAnswers: arg,
    }
    addUserAnswer(questionWithUserAnswers)
    const questionIndex = survey.questions.findIndex(
      (q) => q.id === currentQuestion.id,
    )
    if (questionIndex >= 0) {
      setCurrentQuestionIndex(questionIndex)
    }
  }

  const questionType =
    currentQuestion.answers.length === 1 &&
    currentQuestion.answers[0].value === ''
      ? 'text'
      : currentQuestion.type

  // 4. Description / QA switch
  return (
    <Container className={classes.root} ref={topOfFormRef}>
      <Box className={classes.questionBox}>
        <Typography component="h4" className={classes.stepper}>
          Question : {questionsStep}/{totalNumberOfQuestions}
        </Typography>
        <MobileStepper
          variant="progress"
          steps={totalNumberOfQuestions + 1}
          position="static"
          activeStep={questionsStep}
          className={classes.progress}
          backButton={null}
          nextButton={null}
        />
      </Box>

      {/* TYPE: ONE CHOICE */}
      {questionType === 'one-choice' && (
        <QASingleChoice
          handleChange={handleChange}
          nextButtonIsDisabled={nextButtonIsDisabled}
          nextButtonLabel={nextButtonLabel}
          onNextButtonClick={onNextButtonClick}
          question={currentQuestion}
        />
      )}

      {/* TYPE: MULTIPLE CHOICE */}
      {questionType === 'multiple-choice' && (
        <QAMultipleChoice
          handleChange={handleChange}
          nextButtonIsDisabled={nextButtonIsDisabled}
          nextButtonLabel={nextButtonLabel}
          onNextButtonClick={onNextButtonClick}
          question={currentQuestion}
        />
      )}

      {/* TYPE: TEXT */}
      {questionType === 'text' && (
        <QAText
          handleChange={handleChange}
          nextButtonIsDisabled={nextButtonIsDisabled}
          nextButtonLabel={nextButtonLabel}
          onNextButtonClick={onNextButtonClick}
          question={currentQuestion}
        />
      )}

      {/* SLIDER */}
      {questionType === 'slider' && (
        <QASlider
          handleChange={handleChange}
          nextButtonIsDisabled={nextButtonIsDisabled}
          nextButtonLabel={nextButtonLabel}
          onNextButtonClick={onNextButtonClick}
          question={currentQuestion}
        />
      )}
    </Container>
  )
}
