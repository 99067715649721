import { format } from 'date-fns'
import { fr } from 'date-fns/locale'

export const groupByMonthAndYear = (
  list: carePathwayListItem[],
): GroupedCarePathwayItems => {
  const groupedItems = list.reduce(
    (acc: GroupedCarePathwayItems, item: carePathwayListItem) => {
      const monthYear = format(item.date, 'MMMM yyyy', { locale: fr })
      if (!acc[monthYear]) {
        acc[monthYear] = []
      }
      acc[monthYear].push(item)
      return acc
    },
    {},
  )

  const sortedMonthYears = Object.keys(groupedItems).sort((a, b) => {
    const dateA = new Date(a)
    const dateB = new Date(b)
    return dateB.getTime() - dateA.getTime()
  })

  const sortedGroupedItems: GroupedCarePathwayItems = {}
  sortedMonthYears.forEach((monthYear) => {
    sortedGroupedItems[monthYear] = groupedItems[monthYear]
  })

  return sortedGroupedItems
}

export const supportTypes = {
  HOSPITALIZATION: 'HOSPITALIZATION',
  CONSULTATION: 'CONSULTATION',
}

export const hospitalizationTypes = {
  PLANNED: 'PLANNED',
  NOT_PLANNED: 'NOT_PLANNED',
}

export const isHospitalization = (type: string) =>
  type === supportTypes.HOSPITALIZATION
export const isConsultation = (type: string) =>
  type === supportTypes.CONSULTATION
