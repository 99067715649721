import {
  Box,
  Button,
  Container,
  FilledInput,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  SvgIcon,
  Typography,
} from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ChuTheme } from '../../../@types/theme'
import { ReactComponent as Back } from '../../../assets/icons/icon_back.svg'
import { sendFeedbackAction } from '../../../context/actions/feedback'
import { AppContext } from '../../../context/app-context'
import { settings } from '../../../theme/settings'
import { ValidationDialog } from './ValidationDialog'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    height: 'inherit',
    padding: 0,
    color: theme.palette.white,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      background: theme.palette.linearBackground,
      paddingBottom: theme.spacing(16),
      height: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      overflow: 'scroll',
    },
  },
  header: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      background: theme.palette.linearBackground,
    },

    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      flexFlow: 'column',
      marginTop: theme.spacing(3),
      alignItems: 'center',
    },
    minHeight: '70px',
    alignItems: 'center',
    padding: '10px',
    textAlign: 'left',
  },
  title: {
    fontSize: '17px',
    padding: '10px',
    lineHeight: 1.2,
    [theme.breakpoints.up('md')]: {
      textTransform: 'uppercase',
      paddingBottom: theme.spacing(7),
    },
  },
  back: {
    transform: 'rotate(180deg)',
    margin: '0 10px 10px',
  },
  questionWrapper: {
    backgroundColor: theme.palette.tertiaryColorLight,
    color: theme.palette.primary.main,
    padding: '30px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 700,
      margin: 'auto',
    },
  },
  question: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    padding: '10px 0',
    fontSize: '16px',
    lineHeight: 1.2,
  },
  formControl: {
    maxWidth: 280,
    margin: 'auto',
    display: 'flex',
    flexFlow: 'column',
  },
  textArea: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.main,
    padding: '30px',
    lineHeight: 1.2,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 700,
      margin: 'auto',
    },
  },
  textAreaTitle: {
    color: theme.palette.secondary.main,
    padding: '10px 0',
    fontSize: '16px',
    lineHeight: 1.2,
  },
  textAreaBox: {
    maxWidth: 280,
    margin: 'auto',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      maxWidth: 500,
    },
  },
  transparentButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.palette.white,
  },
  verticalAlign: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export const BugReportPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const initialState = {
    page: '',
    bug: '',
    message: '',
  }
  const [values, setValues] = useState(initialState)
  const { dispatch } = useContext(AppContext)

  const [isOpen, setIsOpen] = useState(false)

  const handleChange = (event: any) => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleClickOpen = (e: any) => {
    e.preventDefault()
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }
  const sendBugReport = async () => {
    await dispatch(sendFeedbackAction(values))
    navigate('/')
  }

  return (
    <>
      <Container className={classes.root}>
        <Box className={classes.header}>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <img src={`../${settings.images.contactApp}`} alt="" />
          </Box>
          <Box className={classes.verticalAlign}>
            <Box sx={{ display: { md: 'none', xs: 'block' } }}>
              <Box className={classes.back}>
                <button
                  className={classes.transparentButton}
                  onClick={() => {
                    navigate('/contact')
                  }}
                >
                  <SvgIcon component={Back} style={{ fontSize: 20 }} />
                </button>
              </Box>
            </Box>
            <Typography className={classes.title}>
              {settings.strings.bugReportPage.title}
            </Typography>
          </Box>
        </Box>
        <form onSubmit={handleClickOpen}>
          <Box className={classes.questionWrapper}>
            <Typography className={classes.question}>
              {settings.strings.bugReportPage.question}
            </Typography>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel id="demo-simple-select-filled-label">
                {settings.strings.bugReportPage.page}
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={values.page}
                onChange={handleChange}
                name="page"
              >
                {settings.strings.bugReportPage.selectPage.map(
                  (select: string, i: number) => (
                    <MenuItem key={i} value={select}>
                      {select}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>

            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel id="demo-simple-select-filled-label">
                {settings.strings.bugReportPage.bug}
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={values.bug}
                onChange={handleChange}
                name="bug"
              >
                {settings.strings.bugReportPage.selectBug.map(
                  (select: string, i: number) => (
                    <MenuItem key={i} value={select}>
                      {select}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          </Box>

          {values.bug !== '' && values.page !== '' && (
            <Box className={classes.textArea}>
              <Typography className={classes.textAreaTitle}>
                {settings.strings.bugReportPage.describePb}
              </Typography>
              <Box className={classes.textAreaBox}>
                <FormControl variant="filled">
                  <FilledInput
                    placeholder="Tapez votre texte ici…"
                    value={values.message}
                    name="message"
                    onChange={handleChange}
                    type="text"
                    multiline={true}
                    style={{ lineHeight: 1 }}
                  />
                </FormControl>
              </Box>

              <Button
                variant="contained"
                size="large"
                color="secondary"
                type="submit"
                disabled={!values.message}
              >
                {settings.strings.bugReportPage.submitBug}
              </Button>
            </Box>
          )}
        </form>
      </Container>

      <ValidationDialog
        open={isOpen}
        onClose={handleClose}
        onClick={sendBugReport}
      />
    </>
  )
}
