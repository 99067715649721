import { TextField } from '@material-ui/core'
import { Field, FieldProps } from 'formik'
import React from 'react'

interface CustomTextFieldProps {
  name: string
  label: string
  className?: string
  multiline?: boolean
  rows?: number
}

const InputField: React.FC<CustomTextFieldProps> = ({
  name,
  label,
  className,
  multiline,
  rows,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <TextField
          {...field}
          label={label}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          fullWidth
          InputLabelProps={{
            style: { color: 'white', fontSize: '16px' },
            shrink: true,
          }}
          className={className}
          {...props}
          multiline={multiline}
          rows={rows}
        />
      )}
    </Field>
  )
}

export default InputField
