import { Box, Container, makeStyles, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import React, { useContext, useEffect } from 'react'
import { ChuTheme } from '../../../@types/theme'
import { getCalendarAction } from '../../../context/actions/calendar'
import { AppContext } from '../../../context/app-context'
import { settings } from '../../../theme/settings'
import { CalendarInfo } from './CalendarInfo'

const CALENDAR_MILESTONE_LABELS: Array<CalendarMilestoneLabel> = [
  '1Month',
  '3Months',
  '6Months',
  '1Year',
]

const CALENDAR_MILESTONE_IMAGES = {
  '1Month': settings.images.calendar1Month,
  '3Months': settings.images.calendar3Months,
  '6Months': settings.images.calendar6Months,
  '1Year': settings.images.calendar1Year,
}

const CALENDAR_MILESTONE_TITLES = {
  '1Month': settings.strings.calendar.title1Month,
  '3Months': settings.strings.calendar.title3Months,
  '6Months': settings.strings.calendar.title6Months,
  '1Year': settings.strings.calendar.title1Year,
}

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 16,
    color: theme.palette.white,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  outOfReanimationContainer: {
    position: 'relative',
  },
  calendarHouseIcon: {
    width: 53,
  },
  calendarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  calendarStepContainer: {
    position: 'relative',
  },
  calendarImg: {
    display: 'block',
    width: 110,
  },
}))

/**
 * Page "Agenda" avec une vue des checkup à faire après 1 mois, 3 mois, 6 mois, et 1 an
 * Pour chacune de ces étapes, affiche si le questionnaire est complété ou bien la date pour laquelle il faut le compléter
 */
export const Calendar = () => {
  const classes = useStyles()
  const { dispatch, calendar } = useContext(AppContext)
  const strings = settings.strings.calendar

  useEffect(() => {
    if (calendar.state === 'idle') {
      dispatch(getCalendarAction())
    }
  }, [dispatch, calendar.state])

  /**
   * Une milestone (e.g. check-up après 3 mois) est affichée si la milestone précédente est terminée (e.g. check-up après 1 mois)
   */
  const isCalendarInfoDisplayed = (label: CalendarMilestoneLabel): boolean => {
    if (!calendar.data) {
      return false
    }

    switch (label) {
      case '1Month':
        return true
      case '3Months':
        return calendar.data['1Month'].completed
      case '6Months':
        return calendar.data['3Months'].completed
      case '1Year':
        return calendar.data['6Months'].completed
      default:
        return false
    }
  }

  /**
   * Une milestone est active si elle n'est pas terminée ET la milestone précédente est terminée
   */
  const isCalendarMilestoneActive = (
    label: CalendarMilestoneLabel,
  ): boolean => {
    if (!calendar.data) {
      return false
    }

    switch (label) {
      case '1Month':
        return !calendar.data['1Month'].completed
      case '3Months':
        return (
          !calendar.data['3Months'].completed &&
          calendar.data['1Month'].completed
        )
      case '6Months':
        return (
          !calendar.data['6Months'].completed &&
          calendar.data['3Months'].completed
        )
      case '1Year':
        return (
          !calendar.data['1Year'].completed &&
          calendar.data['6Months'].completed
        )
      default:
        return false
    }
  }

  /**
   * Détermine le texte à afficher dans la bulle d'information selon l'état de la milestone
   */
  const getCalendarInfoBody = (
    calendarMilestone: CalendarMilestone | undefined,
  ): string => {
    if (!calendarMilestone) {
      return ''
    }
    if (calendarMilestone.completed) {
      return `${calendar.data?.name} ${strings.completed}`
    }
    return `${strings.toDo} ${calendar.data?.name} ${strings.before} ${format(
      calendarMilestone.deadline,
      'dd/MM/yyyy',
    )}`
  }

  const getCalendarMilestoneHeader = (milestoneLabel: CalendarMilestoneLabel) =>
    `${CALENDAR_MILESTONE_TITLES[milestoneLabel]} ${calendar.data?.name}`

  return (
    <Container className={classes.root}>
      {/* TITLE */}
      <Box className={classes.titleContainer}>
        <Typography className={classes.title}>{strings.title}</Typography>
      </Box>

      {/* OUT OF REANIMATION */}
      <Box className={classes.outOfReanimationContainer}>
        <img
          className={classes.calendarHouseIcon}
          src={settings.images.calendarHouseIcon}
          alt=""
        />
        <CalendarInfo position="right" body={strings.outOfReanimation} />
      </Box>

      {/* CALENDAR */}
      <Box className={classes.calendarContainer}>
        {CALENDAR_MILESTONE_LABELS.map((milestoneLabel, index) => (
          <Box key={milestoneLabel} className={classes.calendarStepContainer}>
            <img
              className={classes.calendarImg}
              src={CALENDAR_MILESTONE_IMAGES[milestoneLabel]}
              alt=""
            />
            {isCalendarInfoDisplayed(milestoneLabel) && (
              <CalendarInfo
                position={index % 2 === 0 ? 'left' : 'right'}
                header={getCalendarMilestoneHeader(milestoneLabel)}
                body={getCalendarInfoBody(calendar.data?.[milestoneLabel])}
                active={isCalendarMilestoneActive(milestoneLabel)}
              />
            )}
          </Box>
        ))}
      </Box>
    </Container>
  )
}
