import { AppBar, Box } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'
import Toolbar from '@material-ui/core/Toolbar'
import Restore from '@material-ui/icons/Restore'
import React, { useContext } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { ChuTheme } from '../../@types/theme'
import { ReactComponent as Home } from '../../assets/icons/tabbar_icon_accueil.svg'
import { ReactComponent as Calendar } from '../../assets/icons/tabbar_icon_agenda.svg'
import { ReactComponent as Contact } from '../../assets/icons/tabbar_icon_contact.svg'
import { ReactComponent as Status } from '../../assets/icons/tabbar_icon_statut.svg'
import { AppContext } from '../../context/app-context'
import { settings } from '../../theme/settings'

interface Props {
  link: string
  icon: any
  labelKey: string
  roles?: Array<UserRole>
}

const links: Props[] = [
  {
    link: '/',
    icon: <SvgIcon component={Home} viewBox="0 0 26 26" fontSize="large" />,
    labelKey: 'Accueil',
  },
  {
    link: '/profile',
    icon: <SvgIcon component={Status} viewBox="0 0 25 25" fontSize="large" />,
    labelKey: 'Profil',
  },
  {
    link: '/calendar',
    icon: (
      <SvgIcon component={Calendar} viewBox="0 0 28 28.964" fontSize="large" />
    ),
    labelKey: 'Agenda',
    roles: ['patient'],
  },
  {
    link: '/care-pathway',
    icon: (
      <SvgIcon
        component={Restore}
        style={{ height: 40, width: 40 }}
        viewBox="0 0 30 23.26"
        fontSize="large"
      />
    ),
    labelKey: 'Parcours de soin',
    roles: ['patient'],
  },
  {
    link: '/contact',
    icon: (
      <SvgIcon component={Contact} viewBox="0 0 25 27.191" fontSize="large" />
    ),
    labelKey: 'Contact',
  },
]

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    width: '100%',
  },
  navbar: {
    backgroundColor: theme.palette.navbarColor,
    height: 120,
    width: '100%',
    top: 0,
    left: 0,
    position: 'fixed',
    boxShadow: 'unset',
  },
  grow: {
    flexGrow: 1,
  },
  itemNav: {
    display: 'flex',
    justifyContent: 'center',
    listStyle: 'none',
    maxWidth: '175px',
    '& a': {
      display: 'flex',
      alignItems: 'center',
      padding: '6px 25px 8px',
      width: '100%',
      fontSize: '14px !important',
      color: theme.palette.info.main,
      textDecoration: 'none',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& svg': {
      fontSize: 26,
      marginRight: 10,
    },
  },
  selected: {
    '&$selected': {
      color: theme.palette.primary.light,
      fill: theme.palette.primary.light,
    },
  },
}))

export const HeaderNavigation = () => {
  const classes = useStyles()
  return (
    <AppBar className={classes.navbar}>
      <Container>
        <Toolbar className={classes.root}>
          <Link to="/">
            <img src={settings.images.logo} alt="logo" />
          </Link>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Box className={classes.grow}></Box>
          </Box>
          <TopBarNavigation />
        </Toolbar>
      </Container>
    </AppBar>
  )
}

const LinkItemNav = ({ link, icon, labelKey }: Props) => {
  const classes = useStyles()
  const location = useLocation()
  return (
    <Box component="li" className={classes.itemNav}>
      <NavLink
        to={link}
        className={location.pathname === link ? classes.selected : undefined}
      >
        {icon}
        {labelKey}
      </NavLink>
    </Box>
  )
}

export const TopBarNavigation = () => {
  const { user } = useContext(AppContext)

  return (
    <Box component="ul" display="inline-flex">
      {links
        .filter((link) => {
          if (!link.roles) {
            return true
          }
          return user.data?.role && link.roles.includes(user.data?.role)
        })
        .map((link) => (
          <LinkItemNav key={link.labelKey} {...link} />
        ))}
    </Box>
  )
}
