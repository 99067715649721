import React from 'react'
import { useParams } from 'react-router-dom'
import { ForgottenPasswordRequest } from './ForgottenPasswordRequest'
import { ForgottenPasswordReset } from './ForgottenPasswordReset'

export const ForgottenPassword = () => {
  const { token } = useParams<{ token?: string }>()

  return token ? (
    <ForgottenPasswordReset token={token} />
  ) : (
    <ForgottenPasswordRequest />
  )
}
