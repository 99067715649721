import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import { ChuTheme } from '../../@types/theme'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {},

  typo: {
    textAlign: 'center',
    '& h2, p, span': {
      fontSize: 16,
    },
  },
  dialogTitle: {
    color: theme.palette.secondary.main,
    fontSize: 19,
    paddingTop: theme.spacing(5),
  },
  dialogContent: {
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  buttonBox: {
    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  buttonCancel: {
    padding: '16px 25px',
    [theme.breakpoints.down('sm')]: {
      marginRight: 4,
    },
  },
  buttonConfirm: {
    padding: '16px 25px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 4,
    },
  },
}))

export const DialogMessage = ({
  open,
  title,
  content,
  cancel,
  confirm,
  onClose,
  onConfirm,
  disabled,
}: {
  open: boolean
  title: string
  content: string
  cancel: string
  confirm: string
  onClose: (cb?: any) => void
  onConfirm: () => void
  disabled?: boolean
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-title"
      aria-describedby="confirm-description"
      classes={{ root: classes.typo }}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle id="confirm-title" className={classes.dialogTitle}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="confirm-description"
          className={classes.dialogContent}
        >
          {content}
        </DialogContentText>
      </DialogContent>
      <Box
        mb={4}
        mt={3}
        display="flex"
        justifyContent="space-around"
        className={classes.buttonBox}
      >
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => onClose()}
          disabled={disabled}
          className={classes.buttonCancel}
        >
          {cancel.toUpperCase()}
        </Button>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          onClick={onConfirm}
          disabled={disabled}
          className={classes.buttonConfirm}
        >
          {confirm.toUpperCase()}
        </Button>
      </Box>
    </Dialog>
  )
}
