import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import React, { useEffect, useRef } from 'react'

interface Props {
  message: string
  title?: string
  severity?: NotificationSeverity
  onClose?: () => void
  autoClose?: boolean
  autoCloseTimer?: number
  className?: string
}

export const AlertMessage = ({
  severity,
  title,
  message,
  autoClose,
  onClose,
  autoCloseTimer,
  className,
}: Props) => {
  const timer = useRef<number>()
  useEffect(() => {
    if (autoClose && onClose) {
      timer.current = window.setInterval(onClose, autoCloseTimer || 4500)
    }
    return () => clearInterval(timer.current)
  }, [autoClose, onClose, autoCloseTimer])

  return (
    <Alert
      severity={severity}
      onClick={onClose}
      onClose={onClose}
      className={className}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {message}
    </Alert>
  )
}
