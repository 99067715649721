import { Button, makeStyles } from '@material-ui/core'
import React from 'react'
import { ChuTheme } from '../../@types/theme'

interface Props {
  title: string
  disabled?: boolean
  onClick: () => void
  marginRight?: boolean
}

const useStyles = makeStyles<ChuTheme, Props>((theme) => ({
  root: {
    marginRight: (props) => (props.marginRight ? 20 : undefined),
  },
}))

/**
 * Bouton utilisé pour revenir en arrière
 */
export const PreviousButton: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const { title, disabled, onClick } = props

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      className={classes.root}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </Button>
  )
}
