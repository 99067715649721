import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../../context/app-context'
import { SignIn } from './SignIn/SignIn'

export const Sign = () => {
  const navigate = useNavigate()
  const { user, allForms } = useContext(AppContext)
  if (user.state === 'loaded' && allForms) {
    navigate('/')
  }

  return <SignIn />
}
