const white = '#FFFFFF'
const black = '#000000'
const tertiaryColorLight = '#ECF1F7'
const tertiaryColorMain = '#C9D2DD'
const tertiaryColorDark = '#A9B3BF'
const lightAccentColor = '#F0EFEF'
const mainAccentColor = '#A1BDEB'
const linearBackground = 'linear-gradient(#0D3182, #007AFF)'
const navbarColor = '#F9F9F9'
const selectedColor = '#5EDD00'
const backgroundShapeColor = '#3974C7'
const shadowColor = '#00000033' // Black with 20% opacity

export default {
  black,
  white,
  tertiaryColorLight,
  tertiaryColorMain,
  tertiaryColorDark,
  lightAccentColor,
  mainAccentColor,
  linearBackground,
  navbarColor,
  selectedColor,
  backgroundShapeColor,
  shadowColor,
  primary: {
    light: '#007AFF',
    main: '#0D3182',
    dark: '#0D3182E6',
  },
  secondary: {
    light: '#FED1DE',
    main: '#FC195B',
  },
  info: {
    main: '#A1A1A1',
    dark: '#696767',
  },
  success: {
    light: '#E6FAD7',
    main: '#65B339',
    dark: '#27BDBE',
  },
  warning: {
    light: '#FDAE20',
    main: '#F05B1E',
  },
  background: {
    paper: '#FFFFFF',
    default: linearBackground,
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
}
