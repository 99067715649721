import {
  Box,
  Button,
  Container,
  FormLabel,
  makeStyles,
  Slider,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { ChuTheme } from '../../../../@types/theme'

interface Props {
  question: Question
  handleChange: (arrayOfOneAnswer: Array<any>) => void
  nextButtonIsDisabled: boolean
  onNextButtonClick: () => void
  nextButtonLabel: string
}

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    padding: 0,
    position: 'relative',
    overflowY: 'hidden',
  },
  subquestion: {
    fontSize: '16px',
    textAlign: 'center',
    color: theme.palette.info.dark,
    lineHeight: 1.2,
  },
  formLabel: {
    minHeight: '40px',
    backgroundColor: theme.palette.background.paper,
    borderBottom: `solid 1px ${theme.palette.lightAccentColor}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
  },
  question: {
    fontSize: '18px',
    textAlign: 'center',
    fontWeight: 500,
    color: theme.palette.white,
    lineHeight: 1.2,
    padding: '20px',
    backgroundColor: theme.palette.primary.main,
  },
  sliderBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sliderContainer: {
    width: '100%',
    maxWidth: 300,
    padding: '48px 32px 24px 32px',
  },
  sliderThumb: {
    color: theme.palette.primary.light,
  },
  sliderTrack: {
    color: theme.palette.primary.light,
  },
  sliderRail: {
    color: theme.palette.primary.light,
  },
  helperTextContainer: {
    width: '80%',
  },
  helperText: {
    fontSize: 14,
    color: theme.palette.primary.dark,
    textAlign: 'center',
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    height: '90px',
    padding: '15px',
  },
  button: {
    margin: '5px',
  },
}))

/**
 * Composant appelé dans <QA /> lorsqu'une question est de type "slider"
 * Un slider est affiché pour permettre de choisir une valeur dans un intervalle de valeurs
 * Le label de la question sert de texte d'aide, e.g. "0 = Jamais, 10 = Presque tous les jours"
 */
export const QASlider: React.FC<Props> = ({
  question,
  handleChange,
  nextButtonIsDisabled,
  onNextButtonClick,
  nextButtonLabel,
}) => {
  const classes = useStyles()
  const [sliderValue, setSliderValue] = useState<number>(
    question.answers[0]?.sliderMin || 0,
  )

  // Reset la valeur du slider dès qu'on change de question
  useEffect(() => {
    if (question.answers.length > 0) {
      setSliderValue(question.answers[0].sliderMin || 0)
      handleChange([
        { name: question, value: question.answers[0].sliderMin || 0 },
      ])
    }
  }, [question.name])

  return (
    <Container className={classes.root}>
      <Typography component="h3" className={classes.question}>
        {(question.isSubQuestion && question.instructions) || question.name}
      </Typography>

      {question.isSubQuestion && (
        <FormLabel component="legend" className={classes.formLabel}>
          <Typography className={classes.subquestion}>
            {question.isSubQuestion && question.name}
          </Typography>
        </FormLabel>
      )}

      {question.answers.length > 0 && (
        <Box className={classes.sliderBox}>
          <Box className={classes.sliderContainer}>
            <Slider
              value={sliderValue}
              onChange={(_, value) => {
                if (typeof value !== 'number') {
                  return
                }
                if (question.userAnswers[0]?.value !== value) {
                  setSliderValue(value)
                  handleChange([{ name: question.name, value }])
                }
              }}
              defaultValue={question.answers[0].sliderMin}
              color="primary"
              getAriaValueText={(value) => value.toString()}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={question.answers[0].sliderStep}
              marks
              min={question.answers[0].sliderMin}
              max={question.answers[0].sliderMax}
              classes={{
                thumb: classes.sliderThumb,
                track: classes.sliderTrack,
                rail: classes.sliderRail,
              }}
            />
          </Box>

          <Box className={classes.helperTextContainer}>
            <Typography className={classes.helperText}>
              {question.answers[0].name}
            </Typography>
          </Box>
        </Box>
      )}

      <div>
        <Box className={classes.inputWrapper}>
          <Button
            disabled={nextButtonIsDisabled}
            onClick={onNextButtonClick}
            variant="contained"
            color="secondary"
            size="large"
            className={classes.button}
          >
            {nextButtonLabel}
          </Button>
        </Box>
      </div>
    </Container>
  )
}
