import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { ChuTheme } from '../../@types/theme'
import { Footer } from '../Layout/Footer'
import { BottomNavigationBar } from './BottomNavigationBar'
import { HeaderNavigation } from './HeaderNavigation'

interface PageLayoutProps {
  isAuthenticated: boolean
  children: React.ReactNode
}

const useStyles = makeStyles<ChuTheme, PageLayoutProps>((theme) => {
  const mobileHeight = 'calc(100vh)'
  const mobileHeightAuthenticated = 'calc(100vh - 60px)'
  const desktopHeight = 'calc(100vh)'
  const desktopHeightAuthenticated = 'calc(100vh - 150px)'

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      background: theme.palette.background.default,
      [theme.breakpoints.down('md')]: {
        height: ({ isAuthenticated }) =>
          isAuthenticated ? mobileHeightAuthenticated : mobileHeight,
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: ({ isAuthenticated }) => (isAuthenticated ? 120 : 0),
        paddingBottom: 30,
        height: 'inherit',
        minHeight: ({ isAuthenticated }) =>
          isAuthenticated ? desktopHeightAuthenticated : desktopHeight,
      },
    },
  }
})

export const PageLayout: React.FC<PageLayoutProps> = (props) => {
  const classes = useStyles(props)
  const { isAuthenticated, children } = props

  return (
    <Box>
      {isAuthenticated && (
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
          <HeaderNavigation />
        </Box>
      )}
      <Box className={classes.root}>
        {children}
        {!isAuthenticated && <Footer />}
      </Box>
      {isAuthenticated && (
        <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
          <BottomNavigationBar Link={Link} />
        </Box>
      )}
    </Box>
  )
}
