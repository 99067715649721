import listThematic from './forms'
import images from './images'
import palette from './palette'
import strings from './strings'

const imgPath = 'images/Chut'

export const ChutSettings = {
  theme: {
    palette,
  },
  imgPath,
  images,
  strings,
  listThematic,
}
