import styled from '@emotion/styled'
import React from 'react'

const LinearGradient = styled.div`
  background: linear-gradient(#0d3182, #007aff);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    font-family: 'Roboto';
    font-weight: 200;
  }
`

interface LinearGradienttProps {
  children?: React.ReactNode
}

export const UILoading: React.FC<LinearGradienttProps> = ({ children }) => {
  return <LinearGradient>{children}</LinearGradient>
}
