import { settings } from '../settings'

export default {
  root: {
    margin: '8px 0',
    '&:before': {
      borderColor: `${settings.theme.palette.tertiaryColorMain}`,
    },
  },
  input: {
    background: `${settings.theme.palette.white}`,
    borderRadius: '4px 4px 0 0',
    color: `${settings.theme.palette.primary.main}`,
  },
}
