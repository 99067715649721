import Api from './api'

export async function requestAppointment() {
  try {
    await Api.post('/appointments')
  } catch (error) {
    throw new Error('Could not request appointment')
  }
}

export async function getNextAppointment() {
  try {
    const response = await Api.get('/appointments/incoming')
    return response.data
  } catch (error) {
    throw new Error('Could not request appointment')
  }
}
