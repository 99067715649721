import { Button, makeStyles, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import React from 'react'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { ChuTheme } from '../../../../@types/theme'
import { settings } from '../../../../theme/settings'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    height: 'inherit',
    color: theme.palette.white,
    padding: '50px 30px 30px 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    textAlign: 'center',
  },
  intro: {
    fontSize: '18px',
    lineHeight: 1.2,
  },
  button: {
    margin: '20px',
    width: '250px',
  },
}))
interface SurveyDescriptionProps {
  description: string
}

export const SurveyDescription = ({ description }: SurveyDescriptionProps) => {
  const classes = useStyles()
  const { surveyId } = useParams<{ surveyId: string }>()
  return (
    <Container className={classes.root}>
      <Typography className={classes.intro}>{description}</Typography>
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        size="large"
        component={RouterLink}
        to={`/form/${surveyId}/qa`}
      >
        {settings.strings.survey.buttons.understood.toUpperCase()}
      </Button>
    </Container>
  )
}
