import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { BugReportPage } from './BugReport'
import { ContactHomepage } from './ContactHomepage'

export const Contact: React.FC<any> = () => {
  return (
    <Routes>
      <Route path="bug-report" element={<BugReportPage />} />
      <Route path="/" element={<ContactHomepage />} />
    </Routes>
  )
}
