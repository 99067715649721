import { Grid, makeStyles } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import React from 'react'

import { Typography } from '@material-ui/core'
import { ChuTheme } from '../../../@types/theme'
import InputField from '../../../forms/InputField'
import RadioToggleButtonGroup from '../../../forms/RadioToggleButtonGroup'
import { settings } from '../../../theme/settings'
import { hospitalizationTypes } from './utils'

interface Props {
  values: CreateCarePathwayFormValues
}

const useStyles = makeStyles<ChuTheme>((theme) => ({
  title: {
    fontSize: '16px',
    fontWeight: 500,
    margin: '12px 0',
    color: theme.palette.white,
  },
  type: {
    fontSize: '12px',
    color: theme.palette.white,
  },
  inputField: {
    marginBottom: '12px',
  },
}))

const HospitalisationForm: React.FC<Props> = ({ values }) => {
  const { setFieldValue } = useFormikContext()
  const classes = useStyles()
  return (
    <Grid>
      <Grid item container>
        <Typography component="h2" className={classes.type}>
          {settings.strings.carePathway.create.hospitalizationType}
        </Typography>
      </Grid>
      <RadioToggleButtonGroup
        labels={[
          settings.strings.carePathway.create.planned,
          settings.strings.carePathway.create.notPlanned,
        ]}
        options={[
          hospitalizationTypes.PLANNED,
          hospitalizationTypes.NOT_PLANNED,
        ]}
        name="hospitalization.status"
        value={values.hospitalization.status}
        onChange={(newValue) =>
          newValue && setFieldValue('hospitalization.status', newValue)
        }
      />
      <InputField
        name="hospitalization.service"
        label={settings.strings.carePathway.create.service}
        className={classes.inputField}
      />
      <Grid>
        <Typography component="h2" className={classes.type}>
          {settings.strings.carePathway.create.date}
        </Typography>
        <Field
          component="input"
          type="date"
          name="hospitalization.date"
          fullWidth
          className={classes.inputField}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <InputField
        name="hospitalization.daysInHospital"
        label={settings.strings.carePathway.create.daysNumber}
        className={classes.inputField}
      />
      <InputField
        name="hospitalization.comments"
        label={settings.strings.carePathway.create.comments}
        className={classes.inputField}
        multiline
        rows={6}
      />
    </Grid>
  )
}

export default HospitalisationForm
