import { ThemeProvider } from '@material-ui/core'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Layout } from './components/Layout/Layout'
import { AppContextProvider } from './context/app-context'
import './index.css'
import RootRouter from './router/Routes'
import * as serviceWorker from './serviceWorker'
import theme from './theme/theme'

ReactDOM.render(
  <React.StrictMode>
    <AppContextProvider>
      <ThemeProvider theme={theme}>
        <Layout>
          <BrowserRouter>
            <RootRouter />
          </BrowserRouter>
        </Layout>
      </ThemeProvider>
    </AppContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// todo : register SW
serviceWorker.unregister()
